import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { editData, submitData, Textarea } from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import {
  createWebCourseOpportunities,
  updateWebCourseOpportunities,
} from "../../../../redux/slice/webCourseOpportunitiesSlice";

const WebCourseOpportunitiesAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webCourseOpportunitiesForm");
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addWebCourseOpportunities = submitData(e);

    if (addWebCourseOpportunities) {
      try {
        let response;
        if (dialogueData) {
          const payload = {
            addWebCourseOpportunities,
            webCourseOpportunitiesId: dialogueData._id,
          };
          response = await dispatch(
            updateWebCourseOpportunities(payload)
          ).unwrap();
        } else {
          response = await dispatch(
            createWebCourseOpportunities(addWebCourseOpportunities)
          ).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webCourseOpportunitiesForm`}
      title={`Course Opportunities Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-6 col-lg-8 col-md-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-12">
          <Input
            type={`text`}
            id={`title`}
            name={`title`}
            label={`Title`}
            errorMessage={`Enter Title`}
          />
        </div>
        <div className="col-12">
          <Textarea
            type={`text`}
            id={`responsibilities`}
            name={`responsibilities`}
            label={`Responsibilities`}
            errorMessage={`Enter Responsibilities`}
            row={4}
          />
        </div>
        <div className="col-12">
          <Input
            type={`text`}
            id={`tools`}
            name={`tools`}
            label={`Tools`}
            errorMessage={`Enter Tools`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default WebCourseOpportunitiesAdd;
