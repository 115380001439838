import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webTask: [],
  isLoading: false,
  isSkeleton: false,
};
export const webTaskGet = createAsyncThunk(
  "webTask/webTaskGet",
  async (payload) => {
    return apiInstance.get(`webTask/webTaskGet`);
  }
);
export const webTaskAdd = createAsyncThunk(
  "webTask/webTaskAdd",
  async (payload) => {
    return apiInstance.post("webTask/webTaskAdd", payload);
  }
);
export const webTaskUpdate = createAsyncThunk(
  "webTask/webTaskUpdate",
  async (payload) => {
    return apiInstance.patch(
      `webTask/webTaskUpdate?webTaskId=${payload.webTaskId}`,
      payload.formData
    );
  }
);
export const webTaskDelete = createAsyncThunk(
  "webTask/webTaskDelete",
  async (webTaskId) => {
    return apiInstance.delete(`webTask/webTaskDelete?webTaskId=${webTaskId}`);
  }
);

export const webTaskAction = createAsyncThunk(
  "webTask/enableDisableBanner",
  async (payload) => {
    return apiInstance.put(`webTask/enableDisableBanner?webTaskId=${payload}`);
  }
);

const webTaskSlice = createSlice({
  name: "webTaskSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webTaskGet
    builder.addCase(webTaskGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(webTaskGet.fulfilled, (state, action) => {
      state.webTask = action.payload.webTask;
      state.isSkeleton = false;
    });
    builder.addCase(webTaskGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webTaskCreate
    builder.addCase(webTaskAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webTaskAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webTask.unshift(action.payload.webTask);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webTaskAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webTaskUpdate
    builder.addCase(webTaskUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webTaskUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webTaskIdx = state.webTask.findIndex(
          (webTask) => webTask._id === action.payload.webTask._id
        );
        if (webTaskIdx !== -1) {
          state.webTask[webTaskIdx] = {
            ...state.webTask[webTaskIdx],
            ...action.payload.webTask,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webTaskUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webTaskDelete
    builder.addCase(webTaskDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webTaskDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webTask = state.webTask.filter(
          (webTask) => webTask._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webTaskDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webTaskSlice.reducer;
