import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import LogoSm from "../../assets/images/logoSm.png";
import { useDispatch, useSelector } from "react-redux";
import Navigator from "../extra/Navigator";
import $ from "jquery";
import { useEffect, useState } from "react";
import { logout } from "../../redux/slice/authSlice";
import { warning } from "../extra/Swal/Alert";
import { getLoginUser } from "../../redux/slice/dashBoardSlice";
import { baseURL } from "../util/config";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginUser } = useSelector((state) => state.dashBoard);
  const { admin, isAuth } = useSelector((state) => state.auth);
  const mainAdmin = admin || JSON.parse(sessionStorage.getItem("token"));

  console.log("baseURL + mainAdmin.image", baseURL + mainAdmin.image);

  useEffect(() => {
    if (admin._id) {
      dispatch(getLoginUser(admin._id));
    }
  }, [admin._id]);

  const handleLogout = async () => {
    const data = warning(
      "Logout",
      "All session will be remove?",
      "Logout",
      "ri-logout-circle-r-line"
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(logout());
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  const categoryArray = [
    {
      categoryHead: "Admin",
      categoryPermission: true,
      categoryClass: "activeMenu",
      categoryBody: [
        {
          name: "Dashboard",
          path: "/admin/dashboard",
          navIcon: "ri-dashboard-line",
        },
        {
          name: mainAdmin.name,
          path: "/admin/profile",
          // navIcon: "ri-profile-line",
          navImage: baseURL + mainAdmin?.image,
        },
        {
          name: "Logout",
          navIcon: "ri-logout-circle-r-line",
          onClick: handleLogout,
        },
      ],
    },
    {
      categoryHead: "Website",
      categoryClass: "",
      categoryPermission: true,
      categoryBody: [
        {
          name: "Slider",
          path: "/admin/webSlider",
          navIcon: "ri-user-2-line",
        },
        {
          name: "Courses",
          navIcon: "ri-user-search-line",
          subMenu: [
            {
              subName: "Web Courses",
              subPath: "/admin/webCourses",
            },
            {
              subName: "Course Category",
              subPath: "/admin/webCourseCategory",
            },
            {
              subName: "Course Task",
              subPath: "/admin/webTask",
            },
            {
              subName: "Course Facility",
              subPath: "/admin/webCourseFacility",
            },
            {
              subName: "Course Opportunities",
              subPath: "/admin/webCourseOpportunities",
            },
          ],
        },
        {
          name: "Web Service",
          path: "/admin/webService",
          navIcon: "ri-user-2-line",
        },
        {
          name: "Web Technology",
          path: "/admin/webTechnology",
          navIcon: "ri-user-2-line",
        },
        {
          name: "Portfolio",
          navIcon: "ri-user-search-line",
          subMenu: [
            {
              subName: "Portfolio",
              subPath: "/admin/webPortfolio",
            },
            {
              subName: "Portfolio Category",
              subPath: "/admin/webPortfolioCategory",
            },
          ],
        },
      ],
    },
    {
      categoryHead: "Student",
      categoryClass: "",
      // categoryPermission: loginUser?.isReceptionist ? true : false,
      categoryPermission: true,
      categoryBody: [
        {
          name: "Inquiry",
          navIcon: "ri-user-search-line",
          subMenu: [
            {
              subName: "Pending Inquiry",
              subPath: "/admin/pendingInquiry",
            },
            {
              subName: "Accept Inquiry",
              subPath: "/admin/acceptInquiry",
            },
            {
              subName: "Reject Inquiry",
              subPath: "/admin/rejectInquiry",
            },
          ],
        },
        {
          name: "Student",
          path: "/admin/student",
          navIcon: "ri-user-3-line",
        },
        {
          name: "Student Leave",
          navIcon: "ri-user-shared-2-line",
          subMenu: [
            {
              subName: "Pending Leave",
              subPath: "/admin/pendingStudentLeave",
            },
            {
              subName: "Accept Leave",
              subPath: "/admin/acceptStudentLeave",
            },
            {
              subName: "Reject Leave",
              subPath: "/admin/rejectStudentLeave",
            },
          ],
        },
        {
          name: "Student Atten..",
          path: "/admin/studentAttendance",
          navIcon: "ri-user-follow-line",
        },
        {
          name: "Student Holiday",
          path: "/admin/studentHoliday",
          navIcon: "ri-calendar-event-line",
        },
        {
          name: "Fees",
          navIcon: "ri-wallet-3-line",
          subMenu: [
            {
              subName: "Receipt",
              subPath: "/admin/receipt",
            },
            {
              subName: "Reminding Fees",
              subPath: "/admin/remindingFee",
            },
          ],
        },
        {
          name: "Courses",
          path: "/admin/courses",
          navIcon: "ri-booklet-line",
        },
        {
          name: "Batch",
          path: "/admin/batch",
          navIcon: "ri-group-line",
        },
      ],
    },
    {
      categoryHead: "HR",
      categoryClass: "",
      // categoryPermission: loginUser?.isHr ? true : false,
      categoryPermission: true,
      categoryBody: [
        {
          name: "Staff",
          path: "/admin/staff",
          navIcon: "ri-user-2-line",
        },
        {
          name: "Satff Leave",
          navIcon: "ri-user-shared-2-line",
          subMenu: [
            {
              subName: "Pending Leave",
              subPath: "/admin/pendingLeave",
            },
            {
              subName: "Accept Leave",
              subPath: "/admin/acceptLeave",
            },
            {
              subName: "Reject Leave",
              subPath: "/admin/rejectLeave",
            },
          ],
        },
        {
          name: "Staff Holiday",
          path: "/admin/staffHoliday",
          navIcon: "ri-calendar-check-line",
        },
        {
          name: "Satff Salary",
          navIcon: "ri-bank-card-line",
          subMenu: [
            {
              subName: "Pending Salary",
              subPath: "/admin/pendingSalary",
            },
            {
              subName: "Accept Salary",
              subPath: "/admin/acceptSalary",
            },
            {
              subName: "Reserve Salary",
              subPath: "/admin/reserveSalary",
            },
          ],
        },
        {
          name: "Salary Recepit",
          path: "/admin/salaryRecepts",
          navIcon: "ri-file-paper-2-line",
        },
      ],
    },
  ];

  var webSize = $(window).width();
  return (
    <div className="mainSidebar">
      <SideMenuJS datas={loginUser || admin} />
      <div className="startSideBar">
        <div className="sideBar position-relative">
          {/* <div className="sideBarLogo">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
            <div className="smallLogo">
              <img src={LogoSm} alt="logo" className="smallLogo" />
            </div>
            <i className="ri-close-line closeIcon navToggle"></i>
          </div> */}
          <div className="blackBox navToggle"></div>
          {/* ======= Navigation ======= */}
          <div className="navigation">
            <nav>
              <div className="categoryWiseSideBar">
                {categoryArray.map((categories) => {
                  return (
                    categories.categoryPermission === true && (
                      <div
                        className={`mainSideBar  ${categories.categoryClass}`}
                      >
                        <div className="sideBarHead betBox">
                          <span className="text-overflow-1 d-block">
                            {categories.categoryHead}
                          </span>
                          <i class="ri-arrow-down-s-line"></i>
                        </div>
                        <ul
                          className={`mainMenu ${
                            webSize < 991 ? "mobMenu" : " webMenu"
                          }`}
                        >
                          {categories.categoryBody.map((res, i) => {
                            return (
                              <>
                                <Navigator
                                  name={res?.name}
                                  path={res?.path}
                                  navIcon={res?.navIcon}
                                  navImage={res?.navImage}
                                  onClick={res?.onClick && res?.onClick}
                                >
                                  {res?.subMenu && (
                                    <ul className={`subMenu`}>
                                      <span className="subhead">
                                        {res?.name}
                                      </span>
                                      {res?.subMenu?.map((subMenu) => {
                                        console.log("data", subMenu);
                                        return (
                                          <Navigator
                                            name={subMenu.subName}
                                            path={subMenu.subPath}
                                            onClick={subMenu.onClick}
                                          />
                                        );
                                      })}
                                    </ul>
                                  )}
                                </Navigator>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    )
                  );
                })}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

export const SideMenuJS = ({ datas }) => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    // ============== sidemenu toggle ==================
    $(".deactiveClick:not(.activeMasterMenu) .subMenu").hide();
    const handleNav = (event) => {
      const target = event.currentTarget;
      $(".subMenu").not($(target).next(".subMenu")).slideUp();
      $(".mainMenu i").not($(target).children("i")).removeClass("rotate90");
      $(target).next(".subMenu").slideToggle();
      $(target).children("i").toggleClass("rotate90");
    };
    $(".mainMenu.webMenu > li > a").on("click", handleNav);

    // ============== sidebar toggle ==================
    const handleSidebar = () => {
      // Sidemenu Off In Mobile Menu
      $(".subMenu").slideUp();
      $(".mainMenu i").removeClass("rotate90");
      // Mobile Menu Class
      $(".mainAdminGrid").toggleClass("webAdminGrid");
      $(".mainMenu").toggleClass("mobMenu webMenu");
      setMenu(menu ? false : true);
    };
    $(".navToggle").on("click", handleSidebar);

    // ========== Sidebar Close After Click ==============
    const handleSidebarOff = () => {
      if ($(window).width() <= 992) {
        $(".subMenu").slideUp();
        $(".mainMenu i").removeClass("rotate90");
        $(".mainAdminGrid").addClass("webAdminGrid");
        $(".mainMenu").addClass("mobMenu");
        setMenu(menu ? false : true);
      }
    };
    $(".activeClick").on("click", handleSidebarOff);

    // ============== Sidebar in Manu Design Handler =============
    const handleDefault = () => {
      $(".mainMenu > li").removeClass("beforeActiveMaster afterActiveMaster");
      $(".activeMasterMenu").prev().addClass("beforeActiveMaster");
      $(".activeMasterMenu").next().addClass("afterActiveMaster");
    };

    const handleMasterMenu = () => {
      setTimeout(() => {
        handleDefault();
      }, 10);
    };
    $(".mainMenu > li.activeClick").on("click", handleMasterMenu);

    const handleMasterSubMenu = () => {
      setTimeout(() => {
        handleDefault();
      }, 10);
    };
    $(".mainMenu > li.deactiveClick > .subMenu > li").on(
      "click",
      handleMasterSubMenu
    );
    handleDefault();

    // ============== sidemenu toggle ==================

    const handleCategory = (event) => {
      const target = event.currentTarget;
      $(target).next(".mainMenu").slideToggle();
      $(target).children("i").toggleClass("rotate180");
    };
    $(".categoryWiseSideBar .mainSideBar:not(.activeMenu) > .sideBarHead").on(
      "click",
      handleCategory
    );

    return () => {
      // ============== sidebar toggle ==================
      $(".mainMenu > li > a").off("click", handleNav);
      $(".navToggle").off("click", handleSidebar);

      // ============== Sidebar in Manu Design Handler =============
      $(".activeClick").off("click", handleSidebarOff);

      // ============== Sidebar in Manu Design Handler =============
      $(".mainMenu > li.activeClick").off("click", handleMasterMenu);
      $(".mainMenu > li.deactiveClick > .subMenu > li").off(
        "click",
        handleMasterSubMenu
      );
      // ============== sidemenu toggle ==================
      $(
        ".categoryWiseSideBar .mainSideBar:not(.activeMenu) > .sideBarHead"
      ).off("click", handleCategory);
    };
  }, [menu, datas]);

  return null;
};
