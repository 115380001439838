import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  editData,
  Image,
  mixFormateFormData,
  MultiSelect,
  Select,
  submitData,
  Textarea,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import Button from "../../../extra/Buttons/Button";
import { setToast } from "../../../extra/toast";
import { warning } from "../../../extra/Swal/Alert";
import { baseURL } from "../../../util/config";
import {
  createWebCourse,
  updateWebCourse,
} from "../../../../redux/slice/webCourseSlice";

const WebCourseAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const { webCourseCategory } = useSelector((state) => state.webCourseCategory);
  const { webTask } = useSelector((state) => state.webTask);
  const { webCourseOpportunities } = useSelector(
    (state) => state.webCourseOpportunities
  );

  const courseCategoryOptions = webCourseCategory.map((res) => {
    return { name: res.categoryName, value: res._id };
  });
  const courseOppOptions = webCourseOpportunities.map((res) => {
    return { name: res.title, value: res._id };
  });
  const courseRoadMapOptions = webTask.map((res) => {
    return {
      name: `<div><img class="hw-30 m-10-right" src="${
        baseURL + res.image
      }"/><span>${res.taskName}</span></div>`,
      value: res._id,
    };
  });

  const [learnId, setLearnId] = useState("");
  const [learnData, setLearnData] = useState([]);
  const [learn, setLearn] = useState({
    learnTitle: "",
    learnDescription: "",
  });

  const [roadMapData, setRoadMapData] = useState([
    {
      taskId: "",
      mapIndex: 1,
    },    
  ]);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webCourseForm");
      setLearnData(dialogueData.learnIn);
      setRoadMapData(dialogueData.roadMap);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addWebCourse = submitData(e);

    if (addWebCourse) {
      const courseOpportunity = addWebCourse?.courseOpportunitiesId?.split(",");

      const addSomeCourse = {
        ...addWebCourse,
        learnIn: learnData,
        roadMap: roadMapData,
        courseOpportunitiesId: courseOpportunity,
      };

      const convertFormDataCourse = mixFormateFormData(addSomeCourse);
      console.log("addSomeCourse", addSomeCourse);
      console.log("convertFormDataCourse", convertFormDataCourse);
      console.log("...convertFormDataCourse", ...convertFormDataCourse);
      try {
        let response;
        if (dialogueData) {
          const payload = {
            convertFormDataCourse,
            webCourseId: dialogueData._id,
          };
          response = await dispatch(updateWebCourse(payload)).unwrap();
        } else {
          response = await dispatch(
            createWebCourse(convertFormDataCourse)
          ).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webCourseForm`}
      title={`Course Dialogue`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-8 col-xl-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Home Page Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-center">
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`name`}
                  name={`name`}
                  label={`Name`}
                  errorMessage={`Enter Name`}
                />
                <Textarea
                  type={`text`}
                  id={`tagDescription`}
                  name={`tagDescription`}
                  label={`Tag Description`}
                  errorMessage={`Enter Tag Description`}
                  row={2}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Image
                  id={`icon`}
                  name={`icon`}
                  label={`Icon`}
                  errorMessage={`Enter Icon`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Page Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-start">
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`headTitle`}
                  name={`headTitle`}
                  label={`Head Title`}
                  errorMessage={`Enter Head Title`}
                />
                <Textarea
                  type={`text`}
                  id={`description`}
                  name={`description`}
                  label={`Description`}
                  errorMessage={`Enter Description`}
                  row={2}
                />
                <Input
                  type={`text`}
                  id={`courseLink`}
                  name={`courseLink`}
                  label={`Head Course Link`}
                  errorMessage={`Enter Head Course Link`}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Input
                  type={`file`}
                  id={`image`}
                  name={`image`}
                  label={`Image`}
                  imageHandleClass={`width-300 height-150`}
                  errorMessage={`Enter Image`}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`title`}
                  name={`title`}
                  label={`Title`}
                  errorMessage={`Enter Title`}
                />
                <Textarea
                  type={`text`}
                  id={`titleHtml`}
                  name={`titleHtml`}
                  label={`Title Html`}
                  errorMessage={`Enter Title Html`}
                  row={4}
                />
              </div>
              <div className="col-lg-6 col-12 h-100">
                <Select
                  id={`courseCategoryId`}
                  name={`courseCategoryId`}
                  label={`Course Category`}
                  errorMessage={`Enter Course Category`}
                  option={courseCategoryOptions}
                />
                <Input
                  type={`text`}
                  id={`duration`}
                  name={`duration`}
                  label={`Duration`}
                  errorMessage={`Enter Duration`}
                />
                <Input
                  type={`text`}
                  id={`hours`}
                  name={`hours`}
                  label={`Hours`}
                  errorMessage={`Enter Hours`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Learn In
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-end">
              <div className="col-8">
                <Input
                  type={`text`}
                  id={`learnTitle`}
                  name={`learnTitle`}
                  label={`Learn Title`}
                  value={learn.learnTitle}
                  onChange={(e) =>
                    setLearn({ ...learn, [e.target.name]: e.target.value })
                  }
                  ignore={true}
                />
              </div>
              <div className="col-12">
                <Textarea
                  type={`text`}
                  id={`learnDescription`}
                  name={`learnDescription`}
                  label={`Learn Description`}
                  value={learn.learnDescription}
                  onChange={(e) =>
                    setLearn({ ...learn, [e.target.name]: e.target.value })
                  }
                  ignore={true}
                  row={2}
                />
              </div>
              <div className="col-2">
                <Button
                  className={`bg-gray2 text-darkGray border-solid-darkGray-2`}
                  text={`Add`}
                  type={`button`}
                  onClick={() => {
                    if (learn.learnDescription) {
                      if (learnId >= 0 && learnId !== "") {
                        setLearnData(
                          learnData.map((editVal, editI) =>
                            editI == learnId ? learn : editVal
                          )
                        );
                      } else {
                        setLearnData([...learnData, learn]);
                      }
                      setLearn({ learnTitle: "", learnDescription: "" });
                      setLearnId("");
                    } else {
                      setToast("error", "Enter Learn Description");
                    }
                  }}
                />
              </div>
              <div className="col-12 m-20-top">
                <div className="text-darkGray fs-14 m-5-bottom fw-600">
                  What You'll Learn
                </div>
                <div
                  className="bg-light p-10-y p-20-x border-radius-10"
                  style={{ minHeight: "100px" }}
                >
                  <ul className="mainLearn fs-14">
                    {learnData.map((res, i) => (
                      <li
                        className={`d-flex align-items-center ${
                          learnId === i ? "text-gray" : "text-darkGray"
                        }`}
                      >
                        <div className="fs-18 m-8-right">
                          <i
                            class="ri-edit-2-line"
                            onClick={() => {
                              setLearn({
                                learnTitle: res.learnTitle,
                                learnDescription: res.learnDescription,
                              });
                              setLearnId(i);
                            }}
                          ></i>
                          <i
                            class="ri-delete-bin-line"
                            onClick={() => {
                              const data = warning();
                              data
                                .then((logouts) => {
                                  const yes = logouts.isConfirmed;
                                  console.log("yes", yes);
                                  if (yes) {
                                    setLearnData(
                                      learnData.filter(
                                        (_, editI) => editI !== i
                                      )
                                    );
                                  }
                                })
                                .catch((err) => console.log(err));
                            }}
                          ></i>
                        </div>
                        {res.learnTitle != "" ? (
                          <div>
                            <b>{res.learnTitle}</b> : {res.learnDescription}
                          </div>
                        ) : (
                          <div className="m-15-top">{res.learnDescription}</div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Opportinities
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-center">
              <div className="col-12">
                <MultiSelect
                  type={`text`}
                  id={`courseOpportunitiesId`}
                  name={`courseOpportunitiesId`}
                  label={`Course Opportunities`}
                  errorMessage={`Enter Course Opportunities`}
                  options={courseOppOptions}
                  keys={`newCourseOpportunitiesId`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Roadmap
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-end">
              {roadMapData.map((mapRes, ind) => (
                <div key={ind} className="row m-3 align-items-end">
                  <div className="col-md-6">
                    <Select
                      id={`roadMap${mapRes.mapIndex}`}
                      name={`roadMap${mapRes.mapIndex}`}
                      label={`Course Road Map`}
                      ignore={true}
                      option={courseRoadMapOptions}
                      onChange={(e) => {
                        setRoadMapData((prevRoadMapData) =>
                          prevRoadMapData.map((item, index) =>
                            index === ind ? { ...item, taskId: e } : item
                          )
                        );
                      }}
                      defaultValue={mapRes.taskId}
                    />
                  </div>
                  <div className="col-md-3 col-6">
                    <Input
                      type="text"
                      id={`MapIndex${ind}`}
                      name={`MapIndex${ind}`}
                      label={`Map Index`}
                      value={mapRes.mapIndex}
                      ignore={true}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRoadMapData((prevRoadMapData) =>
                          prevRoadMapData.map((item, index) =>
                            index === ind
                              ? { ...item, mapIndex: newValue }
                              : item
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-6 m-15-bottom">
                    <Button
                      className="bg-danger text-light border-solid-danger-2"
                      icon="ri-delete-bin-line"
                      type="button"
                      onClick={() => {
                        setRoadMapData((prevRoadMapData) =>
                          prevRoadMapData.filter((_, index) => index !== ind)
                        );
                      }}
                    />
                    <Button
                      className="bg-success text-light border-solid-success-2 m-20-left"
                      icon="ri-link"
                      type="button"
                      onClick={() => {
                        setRoadMapData((prevRoadMapData) => [
                          ...prevRoadMapData,
                          {
                            taskId: "",
                            mapIndex: parseInt(mapRes.mapIndex) + 1,
                          },
                        ]);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default WebCourseAdd;
