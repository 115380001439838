import React, { useEffect, useState } from "react";
import "./input.scss";
import $ from "jquery";
import { baseURL } from "../../util/config";

const Input = (props) => {
  const [imagePath, setImagePath] = useState("");
  const handleImage = (e) => {
    if (e.target.files.length > 0) {
      setImagePath(URL?.createObjectURL(e.target.files[0]));
    } else {
      setImagePath("");
    }
  };

  const {
    id,
    className,
    type,
    name,
    label,
    labelClass,
    placeholder,
    value,
    disabled,
    readOnly,
    min,
    max,
    checked,
    minLength,
    maxLength,
    errorMessage,
    autoComplete,
    defaultValue,
    validation,
    validationError,
    ignore,
    onKeyUp,
    onChange,
    onDoubleClick,
    headClassName,
    activeIcon,
    activClick,
    baseURL,
    imageHandleClass,
  } = props;

  const [types, setTypes] = useState(type);

  const hideShow = () => {
    types === "password" ? setTypes("text") : setTypes("password");
  };
  const [error, setError] = useState("opacity-0");
  const checkForm = (e) => {
    if (types === "file" || types === "files") {
      handleImage(e);
    }

    if (e.target.value == "") {
      setError("opacity-100");
    } else {
      setError("opacity-0");
    }
  };

  const [inputRange, setInputRange] = useState(defaultValue);
  const valuePercent = ((defaultValue - min) / (max - min)) * 100;
  const [inputRangePer, setInputRangePer] = useState(valuePercent);

  const handleRangeShow = (eventsValue) => {
    setInputRange(eventsValue);
    const valuePercent = ((eventsValue - min) / (max - min)) * 100;
    setInputRangePer(valuePercent);
  };

  window[`rangeDataSelectFunction-${id}`] = handleRangeShow;
  const primeRange = getComputedStyle(document.documentElement)
    .getPropertyValue("--primeRange")
    .trim();

  return (
    <div className={`inputData ${types} text-start ${headClassName}`}>
      {label && (
        <label
          htmlFor={id}
          className={`${
            (types === "radio" || types === "checkbox") && "m-8-left order-1"
          } ${labelClass}`}
        >
          {label}
        </label>
      )}

      <div className="position-relative">
        <input
          style={
            type === "range"
              ? {
                  background: `linear-gradient(to right, ${primeRange} ${inputRangePer}%, #fff ${inputRangePer}%)`,
                }
              : {}
          }
          type={types}
          name={name}
          className={className}
          id={id}
          onChange={(e) => {
            checkForm(e);
            onChange && onChange(e);
            type == "range" && handleRangeShow(e.target.value);
          }}
          placeholder={placeholder}
          value={value}
          onWheel={(e) => type === "number" && e.target.blur()}
          disabled={disabled}
          readOnly={readOnly}
          checked={checked}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          data-validation={validation}
          data-ignore={types == "search" ? "true" : ignore}
          title={validationError}
          onKeyUp={onKeyUp}
          onDoubleClick={onDoubleClick}
          min={min}
          max={max}
          data-baseURL={baseURL}
        />
        {/* Show Image */}
        {type == "file" && (
          <img
            src={imagePath != "" ? imagePath : null}
            alt="hostImage"
            draggable="false"
            className={`m-15-top ${
              (!imagePath || imagePath == "") && "d-none"
            } ${imageHandleClass}`}
            width={"70px"}
            height={"70px"}
            data-image={name}
            data-class={`showImage`}
            id={`file-${name}`}
          />
        )}
        {type == "range" && (
          <p className={`rangeShower`}>
            <div className="endRange">{inputRange}</div>
            <div className="startRange">
              {min}/{max}
            </div>
          </p>
        )}
        {/* Password hide show */}
        {type === "password" && disabled != true && (
          <div className="passHideShow" onClick={hideShow}>
            {types === "password" ? (
              <i class="ri-eye-line"></i>
            ) : (
              <i class="ri-eye-close-line"></i>
            )}
          </div>
        )}
        {/* Search Icon */}
        {type === "search" && !value && (
          <div className="searching">
            <i className="ri-search-line"></i>
          </div>
        )}
        {activeIcon && (
          <div className="activeIcon" onClick={activClick}>
            <i className={activeIcon}></i>
          </div>
        )}
      </div>
      {type !== "search" && (
        <p className={`errorMessage text-start ${error}`} id={`error-${name}`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
export default Input;

export const Image = (props) => {
  const {
    value,
    name,
    className,
    id,
    label,
    imageHandleClass,
    errorMessage,
    multiple,
    ignore,
    labelClass,
    headClassName,
    baseURL,
  } = props;
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState("opacity-0");
  const [changeValue, setChangeValue] = useState(false);
  const [multiImagePath, setMultiImagePath] = useState([]);

  useEffect(() => {
    setChangeValue(true);
  }, []);

  const handleImage = (e, multi) => {
    if (e.target.files.length > 0) {
      if (multi == "multi") {
        // New Path
        const newImagePaths = [];
        for (let i = 0; i < e.target.files.length; i++) {
          newImagePaths.push(URL.createObjectURL(e.target.files[i]));
        }
        // Old Path
        const addImage = document.getElementById(`${name}-multiImage`);
        for (let i = 0; i < newImagePaths.length; i++) {
          const imageTag = document.createElement("img");
          const divTag = document.createElement("div");
          const removeDiv = document.createElement("div");
          imageTag.src = newImagePaths[i];
          imageTag.setAttribute("data-class", "showImage");
          imageTag.setAttribute("data-new", e.target.files[i].name);
          divTag.appendChild(imageTag);
          divTag.appendChild(removeDiv);
          removeDiv.setAttribute("data-remove", "remove");
          removeDiv.classList.add("ri-close-line");
          divTag.setAttribute("data-index", "index");
          addImage.appendChild(divTag);
        }
        const newImageFileValue = e.target.files;
        const input = document.getElementById(id);

        const dataTransfer = new DataTransfer();
        for (let i = 0; i < multiImagePath.length; i++) {
          dataTransfer.items.add(multiImagePath[i]);
        }
        for (let i = 0; i < newImageFileValue.length; i++) {
          dataTransfer.items.add(newImageFileValue[i]);
        }
        input.files = dataTransfer.files;
        setMultiImagePath(dataTransfer.files);
      } else {
        setImagePath(URL?.createObjectURL(e.target.files[0]));
      }
    } else {
      if (multi === "multi") {
        // Clear the multiImage section when no files selected
        const addImage = document.getElementById(`${name}-multiImage`);
        addImage.innerHTML = "";
      } else {
        setImagePath("");
      }
    }
  };
  const checkForm = (e, multi) => {
    handleImage(e, multi);
    setChangeValue(changeValue == true ? false : true);
    if (e.target.value == "") {
      setError("opacity-100");
    } else {
      setError("opacity-0");
    }
  };

  return (
    <div className={`inputData text-start ${headClassName}`}>
      <ImageScript value={changeValue} setMultiImagePath={setMultiImagePath} />
      {label && (
        <label className={`${labelClass}`} htmlFor={id}>
          {label}
        </label>
      )}

      {multiple ? (
        <>
          <div className="imageBoxMain">
            <div className="boxImage ri-add-line">
              <input
                type="file"
                value={value}
                name={name}
                className={className}
                id={id}
                multiple={true}
                onChange={(e) => checkForm(e, "multi")}
                data-ignore={ignore}
                data-baseURL={baseURL}
              />
            </div>
            <div
              id={`${name}-multiImage`}
              className="d-flex flex-wrap multiImage"
            ></div>
          </div>
        </>
      ) : (
        <>
          <div className="imageBoxMain">
            <div className="boxImage ri-add-line">
              <input
                type="file"
                value={value}
                name={name}
                className={`${className}`}
                id={id}
                onChange={(e) => checkForm(e)}
                data-ignore={ignore}
                data-baseURL={baseURL}
              />
            </div>
            <img
              src={imagePath != "" ? imagePath : null}
              alt="hostImage"
              draggable="false"
              className={`boxImage ${
                (!imagePath || imagePath == "") && "d-none"
              } ${imageHandleClass}`}
              width={"100px"}
              height={"100px"}
              data-image={name}
              data-class={`showImage`}
              id={`file-${name}`}
            />
          </div>
        </>
      )}

      <p
        className={`errorMessage text-start text-danger ${error}`}
        id={`error-${name}`}
      >
        {errorMessage}
      </p>
    </div>
  );
};

const ImageScript = ({ value, setMultiImagePath }) => {
  useEffect(() => {
    const removeImage = (e) => {
      const current = e.target;
      const mainImageTag = $(current)
        .parent()
        .parent()
        .siblings(".boxImage")
        .children();
      const pathImage = $(current).siblings();
      if (mainImageTag[0]?.tagName === "INPUT") {
        const datas = pathImage.attr("data-new");
        if (datas) {
          const imageFileValue = mainImageTag[0].files;
          const newImageFileValue = Array.from(imageFileValue).filter(
            (image) => image.name !== datas
          );
          const dataTransfer = new DataTransfer();
          newImageFileValue.forEach((file) => dataTransfer.items.add(file));
          mainImageTag[0].files = dataTransfer.files;
          setMultiImagePath(dataTransfer.files);
        }
      }

      $(current).parent().remove();
    };

    $(`[data-remove]`).on("click", removeImage);
    return () => {
      $(`[data-remove]`).off("click", removeImage);
    };
  }, [value]);

  return null;
};

export const Textarea = (props) => {
  const {
    id,
    label,
    row,
    placeholder,
    name,
    errorMessage,
    onChange,
    disabled,
    readonly,
    value,
    ignore,
    className,
    lableClass,
    headClassName,
  } = props;
  const [error, setError] = useState("opacity-0");
  const checkForm = (e) => {
    if (e.target.value == "") {
      setError("opacity-100");
    } else {
      setError("opacity-0");
    }
  };
  return (
    <div className={`inputData text-start ${headClassName}`}>
      <label className={`${lableClass}`} htmlFor={id}>
        {label}
      </label>
      <textarea
        id={id}
        rows={row}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => {
          checkForm(e);
          onChange && onChange(e);
        }}
        disabled={disabled}
        readonly={readonly}
        data-ignore={ignore}
        className={className}
      ></textarea>
      <p
        className={`errorMessage text-start text-danger ${error}`}
        id={`error-${name}`}
      >
        {errorMessage}
      </p>
    </div>
  );
};

export const Select = (props) => {
  const {
    id,
    label,
    option,
    onChange,
    className,
    btnClass,
    defaultValue,
    placeholder,
    dataValue,
    name,
    errorMessage,
    angle,
    ignore,
    lableClass,
    headClassName,
  } = props;
  const [error, setError] = useState("opacity-0");
  const [value, setValue] = useState(defaultValue);
  const [key, setKey] = useState(defaultValue);

  $(document).ready(function () {
    const editId = $(`#${id}`)[0]?.value;
    if (editId && option) {
      if (typeof option[0] == "object") {
        const matchingItem = option.find((item) => item.value === editId);
        if (matchingItem) {
          setKey(matchingItem?.name);
        }
      } else {
        setKey(editId);
      }
    }
  });

  return (
    <div
      class={`inputData text-start ${headClassName}`}
      id={`selectOption-${id}`}
    >
      <SelectScript
        value={value}
        dropId={id}
        id={`toggle-${id}`}
        angle={angle}
      />
      {label && (
        <label className={`${lableClass}`} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={`selectMain`}>
        <button
          className={`selectBox betBox m-auto-left ${btnClass} d-flex align-items-center justify-content-between`}
          type="button"
          value={key}
          id={`toggle-${id}`}
        >
          <p className={`showSelectValue`}>
            <p className={`${!key ? "text-gray" : "text-dark"}`}>
              <span
                id={`setValueData-${id}`}
                dangerouslySetInnerHTML={{
                  __html: dataValue
                    ? dataValue
                    : dataValue == ""
                    ? placeholder
                    : key
                    ? key
                    : placeholder,
                }}
              ></span>
            </p>
            <input
              type="text"
              placeholder={placeholder}
              readOnly
              name={name}
              id={id}
              data-ignore={ignore}
              className={`p-0 m-0 h-auto cursor-pointer d-none  ${
                value == placeholder ? "text-gray" : "text-dark"
              } ${className}`}
              value={value ? value : defaultValue}
              data-options={JSON.stringify(option)}
              data-multiSelect={false}
              data-select={true}
            />
          </p>
          <i
            class={`ri-arrow-down-s-line ${
              value == placeholder ? "text-gray" : "text-dark"
            }`}
          ></i>
        </button>
        <p className={`errorMessage text-start  ${error}`} id={`error-${name}`}>
          {errorMessage}
        </p>
        <ul
          className="dropMenu"
          style={{
            top: `${errorMessage ? "calc(100% - 25px)" : "calc(100% - 0px)"} `,
            display: "none",
          }}
        >
          <li
            className="text-gray text-center"
            onClick={() => {
              setValue("");
              setKey("");
              setError("opacity-100");
            }}
          >
            -- {placeholder} --
          </li>
          {option?.map((res, i) => {
            const isObject = typeof res;
            return isObject == "object" ? (
              <li
                onClick={() => {
                  setValue(res.value);
                  setError("opacity-0");
                  setKey(res.name);
                  $(".dropMenu").hide();
                  {
                    onChange && onChange(res.value);
                  }
                }}
                key={`li`}
              >
                <span className="d-flex align-items-center">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: res.name,
                    }}
                  ></span>
                </span>
              </li>
            ) : (
              <li
                onClick={() => {
                  setValue(res);
                  setKey(res);
                  setError("opacity-0");
                  $(".dropMenu").hide();
                  {
                    onChange && onChange(res);
                  }
                }}
                key={`li`}
              >
                <span className="d-flex align-items-center">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: res,
                    }}
                  ></span>
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export const MultiSelect = (props) => {
  const {
    id,
    label,
    options,
    className,
    btnClass,
    placeholder,
    name,
    errorMessage,
    angle,
    onChangeValue,
    keys,
  } = props;

  const [showValue, setShowValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [error, setError] = useState("opacity-0");

  const handleOptionClick = (option) => {
    if (typeof option == "object") {
      const valueToToggle = option.value;
      const nameToToggle = option.name;
      const isSelected = selectedValue.includes(valueToToggle);
      if (!isSelected) {
        // Add New Value
        setShowValue([...showValue, nameToToggle]);
        setSelectedValue([...selectedValue, valueToToggle]);
      } else {
        // Remove Exist Value
        const newSelectedValues = selectedValue.filter(
          (value) => value !== valueToToggle
        );
        setSelectedValue(newSelectedValues);

        const newSelectedNames = showValue.filter(
          (name) => name !== nameToToggle
        );
        setShowValue(newSelectedNames);
      }
    } else {
      const valueToToggle = option;
      const isSelected = selectedValue.includes(valueToToggle);
      if (!isSelected) {
        // Add New Value
        setShowValue([...showValue, option]);
        setSelectedValue([...selectedValue, valueToToggle]);
      } else {
        // Remove Exist Value
        const newSelectedValues = selectedValue.filter(
          (value) => value !== valueToToggle
        );
        setSelectedValue(newSelectedValues);
        setShowValue(newSelectedValues);
      }
    }
    // $(".dropMenu").hide();
  };
  useEffect(() => {
    setError(showValue?.length === 0 ? "opacity-100" : "opacity-0");
  }, [showValue]);
  useEffect(() => {
    setError("opacity-0");
  }, []);

  const selectionValueSetInInput = (selectDataValue) => {
    if (selectDataValue && options) {
      const action = Array.isArray(selectDataValue)
        ? selectDataValue
        : selectDataValue.split(",");
      if (typeof options[0] === "object") {
        const getObjectSelectValuesName = options
          .filter((option) => action.includes(option.value))
          .map((option) => option.name);
        setShowValue(getObjectSelectValuesName);
        setSelectedValue(action);
      } else {
        setShowValue(action);
        setSelectedValue(action);
      }
    }
  };
  window[`handleSelectValue${id}${keys}`] = selectionValueSetInInput;

  const removeSelectionUsingClick = (index) => {
    setShowValue((oldShowValue) =>
      oldShowValue.filter((res, ind) => ind !== index)
    );
    setSelectedValue((oldSelectValue) =>
      oldSelectValue.filter((res, ind) => ind !== index)
    );

    $(`#toggle-${id} ~ .dropMenu`).hide();
  };

  return (
    <div
      className={`inputData text-start ${className}`}
      id={`selectOption-${id}`}
    >
      <SelectScript
        value={selectedValue}
        dropId={id}
        id={`toggle-${id}`}
        angle={angle}
      />
      <label htmlFor={id}>{label}</label>
      <div className={`selectMain`}>
        <button
          className={`selectBox betBox m-auto-left ${btnClass} d-flex align-items-center justify-content-between`}
          type="button"
          value={showValue}
          id={`toggle-${id}`}
        >
          <input
            type="text"
            placeholder={placeholder}
            readOnly
            name={name}
            className={`p-0 m-0 h-auto cursor-pointer d-none ${
              !showValue ? "text-gray" : "text-dark"
            }`}
            value={selectedValue}
            onChange={onChangeValue}
            id={id}
            data-options={JSON.stringify(options)}
            data-multiSelect={true}
            data-keys={keys}
            data-select={true}
          />
          <p className={`m-0 m-10-right showSelectValue`} id="selectShowValue">
            {showValue?.length === 0 ? (
              <span className="text-gray">{placeholder}</span>
            ) : (
              showValue.map((selectedValue, index) => {
                return (
                  <span
                    key={index}
                    className="text-dark multiSelectionDisplay d-inline-flex align-items-center"
                    id="dataValidSelect"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: selectedValue,
                      }}
                    ></span>
                    <span
                      onClick={() => removeSelectionUsingClick(index)}
                      className="closeSelectBtn fs-14 m-5-left"
                    >
                      <i class="ri-close-circle-fill"></i>
                    </span>
                  </span>
                );
              })
            )}
          </p>
          <i
            className={`ri-arrow-down-s-line ${
              showValue?.length === 0 ? "text-gray" : "text-dark"
            }`}
          ></i>
        </button>
        <p className={`errorMessage text-start ${error}`} id={`error-${name}`}>
          {errorMessage}
        </p>
        <ul className="dropMenu">
          {options?.map((option, index) => {
            console.log(option);
            return (
              <li
                key={index}
                className={
                  selectedValue.includes(option.value || option)
                    ? "selectedItems text-second"
                    : ""
                }
                onClick={() => {
                  handleOptionClick(option);
                  setError("opacity-0");
                }}
              >
                <span
                  className={`${
                    selectedValue.includes(option.value || option)
                      ? "opacity-100"
                      : "opacity-0"
                  } selectHighlightIcon m-5-right`}
                >
                  <i class="ri-check-line"></i>
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: typeof option === "object" ? option.name : option,
                  }}
                ></span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const SelectScript = ({ id, angle, dropId }) => {
  useEffect(() => {
    $(".dropMenu").hide();
    const handleDrop = () => {
      // const bodyHeight = $(document).innerHeight()
      const bodyHeight = $(window).height();
      const dropdownId = document.getElementById(`selectOption-${dropId}`);

      const rect = dropdownId.getBoundingClientRect();

      // debugger
      const bodyMejor = bodyHeight - rect.top;
      console.log("bodyHeight", bodyHeight);
      console.log("rect", rect);
      console.log("bodyMejor", bodyMejor);

      if (bodyMejor < 230) {
        $(`#${id} ~ .dropMenu`).removeClass("bottomSide");
        $(`#${id} ~ .dropMenu`).addClass("topSide");
      } else {
        $(`#${id} ~ .dropMenu`).removeClass("topSide");
        $(`#${id} ~ .dropMenu`).addClass("bottomSide");
      }

      $(`#${id} ~ .dropMenu`).slideToggle();
    };
    const handleDocumentClick = (event) => {
      const dropMenu = $(`#${id} ~ .dropMenu`);
      const target = $(event.target);
      const targerId = target[0].id;
      const extraChildIgnore1 = target[0]?.parentElement?.id;
      const extraChildIgnore2 = target[0]?.parentElement?.parentElement?.id;
      const extraChildIgnore3 =
        target[0]?.parentElement?.parentElement?.parentElement?.id;

      if (
        !target.closest(dropMenu).length &&
        targerId !== id &&
        extraChildIgnore1 !== "selectShowValue" &&
        targerId !== "selectShowValue" &&
        extraChildIgnore1 !== id &&
        extraChildIgnore2 !== "selectShowValue" &&
        extraChildIgnore3 !== id
      ) {
        dropMenu.slideUp();
      }
    };

    $(document).on("click", handleDocumentClick);

    $(`#${id}`).on("click", handleDrop);
    return () => {
      $(`#${id}`).off("click", handleDrop);
      $(document).off("click", handleDocumentClick);
    };
  }, []);

  return null;
};

export const submitData = (e) => {
  e.preventDefault();
  const formDataObject = {};
  var hasEmptyField = false;

  // normal text feild error
  const textBox = e.target.querySelectorAll('input[type="text"]');
  const numberBox = e.target.querySelectorAll('input[type="number"]');
  const passwordBox = e.target.querySelectorAll('input[type="password"]');
  const hiddenBox = e.target.querySelectorAll('input[type="hidden"]');
  const textareaBox = e.target.querySelectorAll("textarea");
  const dateBox = e.target.querySelectorAll('input[type="date"]');
  const timeBox = e.target.querySelectorAll('input[type="time"]');
  const dateTimeLocal = e.target.querySelectorAll(
    'input[type="datetime-local"]'
  );
  const colorBox = e.target.querySelectorAll('input[type="color"]');
  const monthBox = e.target.querySelectorAll('input[type="month"]');
  const weekBox = e.target.querySelectorAll('input[type="week"]');
  const rangeBox = e.target.querySelectorAll('input[type="range"]');
  const urlBox = e.target.querySelectorAll('input[type="url"]');
  const emailBox = e.target.querySelectorAll('input[type="email"]');

  const allData = [
    ...textBox,
    ...numberBox,
    ...textareaBox,
    ...passwordBox,
    ...hiddenBox,
    ...dateBox,
    ...timeBox,
    ...dateTimeLocal,
    ...colorBox,
    ...monthBox,
    ...weekBox,
    ...rangeBox,
    ...urlBox,
    ...emailBox,
  ];

  // Check All Inputs Check In Form Datas
  for (let i = 0; i < allData.length; i++) {
    const key = allData[i].name;
    var value = allData[i].value;
    const title = allData[i].title;
    const validation = allData[i].getAttribute("data-validation");
    const ignore = allData[i].getAttribute("data-ignore");
    const inputError = allData[i].classList;
    var errorData = e.target.querySelectorAll(`#error-${key}`)[0].classList;
    var errorMessages = e.target.querySelectorAll(`#error-${key}`)[0];

    if (!value && ignore !== "true") {
      // In Any feilds In Not Required that Ignore Error
      inputError.add("inputError");
      errorData.remove("opacity-0");
      errorData.add("opacity-100");
      hasEmptyField = true;
    } else {
      if (validation) {
        // This Validation Check Regex In Fields and Error Show
        let regex = new RegExp(validation);
        const pattern = regex.test(value);
        if (!pattern) {
          errorMessages.textContent = title;
          inputError.add("inputError");
          errorData.remove("opacity-0");
          errorData.add("opacity-100");
          hasEmptyField = true;
        } else {
          allData[i].setCustomValidity("");
          inputError.remove("inputError");
          errorData.remove("opacity-100");
          errorData.add("opacity-0");
          formDataObject[key] = value;
        }
      } else {
        // If Fiels is Null So that Error Show
        allData[i].setCustomValidity("");
        inputError.remove("inputError");
        errorData.remove("opacity-100");
        errorData.add("opacity-0");
        formDataObject[key] = value;
      }
    }
  }
  // Image & Multiple Image Set & Validation
  const file = e.target.querySelectorAll('input[type="file"]');

  if (file) {
    for (let i = 0; i < file.length; i++) {
      const key = file[i].name;
      var errorData = e.target.querySelectorAll(`#error-${key}`)[0].classList;
      const ignore = file[i].getAttribute("data-ignore");

      if (!file[i].multiple) {
        const value = file[i].files[0];
        const imgSrc = e.target.querySelectorAll(`#file-${key}`)[0].src;
        if (imgSrc == "") {
          if (ignore !== "true") {
            errorData.remove("opacity-0");
            errorData.add("opacity-100");
            hasEmptyField = true;
          }
        } else {
          errorData.remove("opacity-100");
          errorData.add("opacity-0");
          if (value == "" || value == undefined) {
            formDataObject[key] = imgSrc;
          } else {
            formDataObject[key] = value;
          }
        }
      } else {
        const value = file[i].files;
        const multiImage = e.target.querySelectorAll(`#${key}-multiImage`)[0]
          .children;
        const multiImageArray = [];
        for (let i = 0; i < multiImage.length; i++) {
          const newAttre = multiImage[i]?.children;
          if (!newAttre[0].attributes["data-new"]) {
            multiImageArray.push(multiImage[i].children[0].src);
          }
        }
        const finalValue = [...multiImageArray, ...value];
        console.log("finalValue", finalValue);
        if (finalValue <= 0) {
          if (ignore !== "true") {
            errorData.remove("opacity-0");
            errorData.add("opacity-100");
            hasEmptyField = true;
          }
        } else {
          errorData.remove("opacity-100");
          errorData.add("opacity-0");
          formDataObject[key] = finalValue;
        }
      }
    }
  }

  // Radio Buttons Validation
  const radio = e.target.querySelectorAll('input[type="radio"]');
  var radioBtn = [];
  for (let i = 0; i < radio.length; i++) {
    radioBtn.push(radio[i].name);
  }
  const radioArray = Array.from(new Set(radioBtn));
  for (let i = 0; i < radioArray.length; i++) {
    const allRadio = e.target.elements[radioArray[i]];
    let radioValidationIgnore = false;
    for (let i = 0; i < allRadio.length; i++) {
      const ignore = allRadio[i].getAttribute("data-ignore");
      if (ignore) {
        radioValidationIgnore = true;
      }
    }
    if (allRadio.value == "") {
      if (radioValidationIgnore !== true) {
        for (let i = 0; i < allRadio.length; i++) {
          allRadio[i].classList.add("error-radio");
          allRadio[i].parentElement.nextSibling.classList.add("text-danger");
          allRadio[i].parentElement.previousElementSibling.classList.add(
            "text-danger"
          );
        }
        hasEmptyField = true;
      }
    } else {
      for (let i = 0; i < allRadio.length; i++) {
        allRadio[i].classList.remove("error-radio");
        allRadio[i].parentElement.nextSibling.classList.remove("text-danger");
        allRadio[i].parentElement.previousElementSibling.classList.remove(
          "text-danger"
        );
      }
      formDataObject[radioArray[i]] = allRadio.value;
    }
  }

  // Checkbox & Multiple Check Box Validation
  const checkbox = e.target.querySelectorAll('input[type="checkbox"]');
  var checkboxBtn = [];
  for (let i = 0; i < checkbox.length; i++) {
    checkboxBtn.push(checkbox[i].name);
  }
  const checkboxArray = [...new Set(checkboxBtn)];
  for (let i = 0; i < checkboxArray.length; i++) {
    const allCheckbox = e.target.elements[checkboxArray[i]];

    if (allCheckbox instanceof NodeList || Array.isArray(allCheckbox)) {
      let checkboxValidationIgnore = false;
      let checkboxArrayValueCheck = false;

      for (let i = 0; i < allCheckbox.length; i++) {
        const ignore = allCheckbox[i].getAttribute("data-ignore");
        if (allCheckbox[i].checked) {
          checkboxArrayValueCheck = true;
        }
        if (ignore) {
          checkboxValidationIgnore = true;
        }
      }
      if (!checkboxArrayValueCheck) {
        if (checkboxValidationIgnore !== true) {
          for (let i = 0; i < allCheckbox.length; i++) {
            allCheckbox[i].classList.add("error-checkbox");
            allCheckbox[i].parentElement.nextSibling.classList.add(
              "text-danger"
            );
            allCheckbox[i].parentElement.previousElementSibling.classList.add(
              "text-danger"
            );
          }
          hasEmptyField = true;
        }
      } else {
        let setCheckBoxArray = [];
        for (let i = 0; i < allCheckbox.length; i++) {
          allCheckbox[i].classList.remove("error-checkbox");
          allCheckbox[i].parentElement.nextSibling.classList.remove(
            "text-danger"
          );
          allCheckbox[i].parentElement.previousElementSibling.classList.remove(
            "text-danger"
          );
          const key = allCheckbox[i].name;
          if (allCheckbox[i].checked) {
            setCheckBoxArray.push(allCheckbox[i].value);
          }
          formDataObject[key] = setCheckBoxArray;
        }
      }
    } else {
      const ignore = allCheckbox.getAttribute("data-ignore");
      if (!allCheckbox.checked) {
        if (ignore !== "true") {
          allCheckbox.classList.add("error-checkbox");
          allCheckbox.parentElement.nextSibling.classList.add("text-danger");
          allCheckbox.parentElement.previousElementSibling.classList.add(
            "text-danger"
          );
          hasEmptyField = true;
        }
      } else {
        allCheckbox.classList.remove("error-checkbox");
        allCheckbox.parentElement.nextSibling.classList.remove("text-danger");
        allCheckbox.parentElement.previousElementSibling.classList.remove(
          "text-danger"
        );
        const value = allCheckbox.value;
        const key = allCheckbox.name;
        formDataObject[key] = value;
      }
    }
  }

  if (hasEmptyField) {
    return; // Prevent form submission if there are empty fields
  }
  // if (!hasEmptyField) {
  //   e.target.reset();
  // }
  return formDataObject;
};

export const editData = (editData, formId, BASEURL = baseURL) => {
  const editFormData = document.getElementById(formId);
  if (editFormData) {
    for (const key in editData) {
      if (editData.hasOwnProperty(key)) {
        const value = editData[key];

        const inputElements = editFormData.querySelectorAll(`[name="${key}"]`);
        if (inputElements.length > 0) {
          const inputType = inputElements[0].type;
          // Set Value in Radio and Checkbox Values
          if (inputType === "radio" || inputType === "checkbox") {
            for (const radio of inputElements) {
              if (Array.isArray(value)) {
                for (const multiValus of value) {
                  if (
                    radio.value === multiValus ||
                    radio.value == multiValus.toString()
                  ) {
                    radio.checked = true;
                  }
                }
              } else {
                if (radio.value === value || radio.value == value.toString()) {
                  radio.checked = true;
                }
              }
            }
          } else if (inputType === "file") {
            // Set Value in Image & Multiple Images
            if (typeof value == "string") {
              const imgSibling = editFormData.querySelector(
                `[data-image="${key}"]`
              );
              const addUrl = inputElements[0].getAttribute("data-baseURL");
              if (imgSibling) {
                imgSibling.src = addUrl !== "false" ? BASEURL + value : value;
                imgSibling.className = `${imgSibling.className}  showImage d-block `;
                imgSibling.classList.remove("d-none");
              }
            } else {
              const imageValue = editFormData.querySelector(
                `#${key}-multiImage`
              );
              const addUrl = inputElements[0].getAttribute("data-baseURL");
              if (imageValue.children.length <= 0) {
                for (let i = 0; i < value.length; i++) {
                  const imageTag = document.createElement("img");
                  const divTag = document.createElement("div");
                  const removeDiv = document.createElement("div");
                  imageTag.src =
                    addUrl !== "false" ? BASEURL + value[i] : value[i];
                  imageTag.setAttribute("data-class", "showImage");
                  divTag.appendChild(imageTag);
                  divTag.appendChild(removeDiv);
                  removeDiv.setAttribute("data-remove", "remove");
                  removeDiv.classList.add("ri-close-line");
                  divTag.setAttribute("data-index", "index");
                  imageValue.appendChild(divTag);
                }
              }
            }
          } else if (inputType === "time") {
            // Set Time Value
            const newHours = value.split(":");
            const [hrs, mins] = newHours;
            let newTimeVal;
            if (mins.length == 1) {
              newTimeVal = `${value}0`;
            } else if (hrs.length == 1) {
              newTimeVal = `0${value}`;
            } else if (mins.length == 1 && hrs.length == 1) {
              newTimeVal = `0${value}0`;
            } else {
              newTimeVal = value;
            }
            inputElements[0].value = newTimeVal;
          } else {
            const selectElemets = editFormData.querySelectorAll(
              `#toggle-${inputElements[0].id}`
            );
            // Set Select And Multiple Select With Value and Object Value
            if (selectElemets && selectElemets.length > 0) {
              selectElemets[0].value = value;
              const checkMultiSelect =
                inputElements[0].getAttribute("data-multiSelect");
              if (checkMultiSelect === "true") {
                const checkMultiSelect =
                  inputElements[0].getAttribute("data-keys");
                window[
                  `handleSelectValue${inputElements[0].id}${checkMultiSelect}`
                ](value);
              } else {
                // Single Select Value And Obj Value
                const getSelectOption = JSON.parse(
                  inputElements[0].getAttribute("data-options")
                );
                const setOptionValueId = editFormData.querySelector(
                  `#setValueData-${inputElements[0].id}`
                );
                let setSelectValueData = value;
                if (typeof getSelectOption[0] == "object") {
                  // Object Value
                  const findSelectObjValue = getSelectOption.find(
                    (option) => option.value === value
                  );
                  if (findSelectObjValue) {
                    setSelectValueData = findSelectObjValue?.name;
                    inputElements[0].value = value;
                  } else {
                    setSelectValueData = "";
                    inputElements[0].value = "";
                  }
                }
                setOptionValueId.innerText = setSelectValueData;
                setOptionValueId.style.color = "#000";
              }
            }

            if (inputType == "range") {
              window[`rangeDataSelectFunction-${inputElements[0].id}`](value);
            }
            // =============== Normal Input Text Value Set ===============
            const checkSelect = inputElements[0].getAttribute("data-select");
            if (!checkSelect) {
              inputElements[0].value = value;
            }
            if (inputElements[1]) {
              inputElements[1].value = value;
            }
          }
        }
      }
    }
  }
};

export const clearFormFields = (formId) => {
  var form = document.getElementById(formId);

  if (!form) {
    console.error("Form with ID '" + formId + "' not found.");
    return;
  }

  for (var i = 0; i < form.elements.length; i++) {
    var element = form.elements[i];

    if (element.tagName === "INPUT") {
      element.value = "";
    }
  }
};

export function objectToFormData(obj) {
  const formData = new FormData();

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((fileSet, index) => {
        if (Array.isArray(fileSet)) {
          fileSet.forEach((file, fileIndex) => {
            formData.append(`${key}[${index}][${fileIndex}]`, file);
          });
        } else {
          // Handle non-array case (e.g., profileImage: Array(2))
          formData.append(`${key}[${index}]`, fileSet);
        }
      });
    } else {
      formData.append(key, obj[key]);
    }
  }

  return formData;
}

export function mixFormateFormData(obj, single) {
  const formData = new FormData();

  const appendFormData = (key, value) => {
    if (value instanceof File) {
      // If the value is a file, append it directly
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      // Handle arrays
      value.forEach((item, index) => {
        if (typeof item === "object" && item !== null) {
          // If the array item is an object, recursively append its properties
          if (item instanceof File) {
            // If the value is a file, append it directly
            formData.append(key, item);
          } else {
            Object.keys(item).forEach((nestedKey) => {
              appendFormData(`${key}[${index}][${nestedKey}]`, item[nestedKey]);
            });
          }
        } else {
          // Otherwise, append the array item directly
          if (single == "single") {
            formData.append(key, item);
          } else {
            formData.append(`${key}[${index}]`, item);
          }
        }
      });
    } else if (typeof value === "object" && value !== null) {
      // Handle objects
      Object.keys(value).forEach((nestedKey) => {
        appendFormData(`${key}[${nestedKey}]`, value[nestedKey]);
      });
    } else {
      // Handle primitive values
      formData.append(key, value);
    }
  };

  // Process the main object
  Object.keys(obj).forEach((key) => {
    appendFormData(key, obj[key]);
  });

  return formData;
}
