import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webPortfolioCategory: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebPortfolioCategory = createAsyncThunk(
  "webPortfolioCategory/getWebPortfolioCategory",
  async (payload) => {
    return apiInstance.get(`webPortfolioCategory/getWebPortfolioCategory`);
  }
);
export const createWebPortfolioCategory = createAsyncThunk(
  "webPortfolioCategory/createWebPortfolioCategory",
  async (payload) => {
    return apiInstance.post("webPortfolioCategory/createWebPortfolioCategory", payload);
  }
);
export const updateWebPortfolioCategory = createAsyncThunk(
  "webPortfolioCategory/updateWebPortfolioCategory",
  async (payload) => {
    return apiInstance.patch(
      `webPortfolioCategory/updateWebPortfolioCategory?webPortfolioCategoryId=${payload.webPortfolioCategoryId}`,
      payload.addWebPortfolioCategory
    );
  }
);
export const deleteWebPortfolioCategory = createAsyncThunk(
  "webPortfolioCategory/deleteWebPortfolioCategory",
  async (webPortfolioCategoryId) => {
    return apiInstance.delete(
      `webPortfolioCategory/deleteWebPortfolioCategory?webPortfolioCategoryId=${webPortfolioCategoryId}`
    );
  }
);

const webPortfolioCategorySlice = createSlice({
  name: "webPortfolioCategorySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebPortfolioCategory
    builder.addCase(getWebPortfolioCategory.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebPortfolioCategory.fulfilled, (state, action) => {
      state.webPortfolioCategory = action.payload.webPortfolioCategory;
      state.isSkeleton = false;
    });
    builder.addCase(getWebPortfolioCategory.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webPortfolioCategoryCreate
    builder.addCase(createWebPortfolioCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebPortfolioCategory.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webPortfolioCategory.unshift(action.payload.webPortfolioCategory);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebPortfolioCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebPortfolioCategory
    builder.addCase(updateWebPortfolioCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebPortfolioCategory.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webPortfolioCategoryIdx = state.webPortfolioCategory.findIndex(
          (webPortfolioCategory) => webPortfolioCategory._id === action.payload.webPortfolioCategory._id
        );
        if (webPortfolioCategoryIdx !== -1) {
          state.webPortfolioCategory[webPortfolioCategoryIdx] = {
            ...state.webPortfolioCategory[webPortfolioCategoryIdx],
            ...action.payload.webPortfolioCategory,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebPortfolioCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    // deleteWebPortfolioCategory
    builder.addCase(deleteWebPortfolioCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWebPortfolioCategory.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webPortfolioCategory = state.webPortfolioCategory.filter(
          (webPortfolioCategory) => webPortfolioCategory._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(deleteWebPortfolioCategory.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webPortfolioCategorySlice.reducer;
