import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../extra/Swal/Alert";
import {
  deleteWebCourseOpportunities,
  getWebCourseOpportunities,
} from "../../../../redux/slice/webCourseOpportunitiesSlice";
import WebCourseOpportunitiesAdd from "./WebCourseOpportunitiesAdd";
const WebCourseOpportunities = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { webCourseOpportunities } = useSelector(
    (state) => state.webCourseOpportunities
  );

  // Pagination BOTH

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getWebCourseOpportunities());
  }, [page]);

  useEffect(() => {
    setData(webCourseOpportunities);
  }, [webCourseOpportunities]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteWebCourseOpportunities(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const webCourseOpportunitiesTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Title",
      body: "title",
      sorting: { type: "client" },
    },
    {
      Header: "Responsibilities",
      body: "responsibilities",
      sorting: { type: "client" },
      width: "500px",
      tdClass: "text-wrap",
    },
    {
      Header: "Tools",
      body: "tools",
      sorting: { type: "client" },
      width: "500px",
      tdClass: "text-wrap",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray border-solid-darkGray-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "webCourseOpportunities",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-danger-light text-danger border-solid-danger-1 m-5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Web Slider"} icon={`ri-group-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Searching
              type={`client`}
              data={webCourseOpportunities}
              setData={setData}
              column={webCourseOpportunitiesTable}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m-20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "webCourseOpportunities" }));
              }}
            />
          </div>
        </div>
      </div>

      {/* Client */}
      <Table
        data={webCourseOpportunities}
        mapData={webCourseOpportunitiesTable}
        Page={page}
        PerPage={rowsPerPage}
      />

      {/* Client */}
      <Pagination
        type={"client"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={data?.length}
      />

      {dialogue && dialogueType === "webCourseOpportunities" && (
        <WebCourseOpportunitiesAdd />
      )}
    </>
  );
};

export default WebCourseOpportunities;
