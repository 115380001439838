import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import { baseURL } from "../../../util/config";
import { useEffect, useState } from "react";
import { getSingleWebCourse } from "../../../../redux/slice/webCourseSlice";

const WebCoursesShow = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { webCourseSingle } = useSelector((state) => state.webCourse);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  useEffect(() => {
    dispatch(getSingleWebCourse(dialogueData));
  }, []);
  useEffect(() => {
    setData(webCourseSingle);
  }, [webCourseSingle]);

  return (
    <DialogBox
      id={`webCourseForm`}
      title={`Course Show Dialogue`}
      columns={`col-xxl-8 col-xl-9 col-11`}
      foot={true}
    >
      <div className="courseShow p-30">
        <div className="row">
          <div className="col-md-4 col-12">
            <div
              className={`courseCategory border-radius-10 text-start p-55-xxxl-x p-30-xl-x p-20-x p-xl-30-y p-20-y border-solid-gray2-1 cursor-pointer trans-3`}
            >
              <div className="courseIcon hw-xxxl-75 hw-xxl-70 hw-sm-60 hw-50 bg-second p-md-15 p-12 border-round-50">
                <img
                  src={baseURL + data?.icon}
                  alt="course"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div className="courseName m-15-top fw-700 fs-xxl-18 fs-xl-16 fs-14">
                {data?.name}
              </div>
              <div className="courseDescription text-gray m-15-top fw-500 lh-lg-30 lh-26 fs-xxl-16 fs-xl-14 fs-12">
                {data?.description}
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12">
            <div
              className={`border-radius-10 text-start p-30-xl-x p-20-x p-xl-30-y p-20-y h-100 border-solid-gray2-1 cursor-pointer trans-3`}
            >
              <div className={`text-center`}>
                <div className="row justify-content-center">
                  <div className="col-12 d-lg-flex">
                    <div className="courseMainBanner">
                      <img
                        src={baseURL + data?.image}
                        alt=""
                        className="height-xxl-150 border-radius-5 width-240"
                      />
                      <h2 className="fs-25 fw-700 m-10-top">
                        {data?.headTitle}
                      </h2>
                    </div>
                    <div className="text-start p-20-x">
                      <p className="text-gray fw-500 lh-26 fs-xl-14 fs-12">
                        {data?.tagDescription}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 m-15-top">
            <div
              className={`border-radius-10 text-start p-30-xl-x p-20-x p-xl-30-y p-20-y h-100 border-solid-gray2-1 cursor-pointer trans-3`}
            >
              <div className="courseDetails">
                <div className="p-20-bottom fw-700 fs-lg-24 fs-md-22 fs-18">
                  {data?.title}
                </div>
                <div
                  className="fs-16 lh-30 text-darkGray"
                  dangerouslySetInnerHTML={{
                    __html: data?.titleHtml,
                  }}
                ></div>
                <div className="p-20-y fw-700 fs-lg-24 fs-md-22 fs-18">
                  What You'll Learn:
                </div>
                <ul className="fs-16 lh-30 text-darkGray">
                  {data?.learnIn?.map((txt) => (
                    <li className={`${!txt.learnTitle && "p-10-y"}`}>
                      {txt.learnTitle && <b>{txt.learnTitle}:</b>}{" "}
                      {txt.learnDescription}
                    </li>
                  ))}
                </ul>
                <div className="p-20-y fw-700 fs-lg-24 fs-md-22 fs-18">
                  Durations:
                </div>
                <ul className="fs-16 lh-30 fw-700 text-darkGray">
                  <li className="d-flex">
                    <div>Course Duration :</div>
                    <div className="text-second m-10-left">
                      {data?.duration}{" "}
                    </div>
                  </li>
                  <li className="d-flex">
                    <div>Lecture Duration :</div>
                    <div className="text-second m-10-left"> {data?.hours} </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 m-15-top">
            <div
              className={`border-radius-10 text-start p-30-xl-x p-20-x p-xl-30-y p-20-y h-100 border-solid-gray2-1 cursor-pointer trans-3 `}
            >
              <div className="p-20-bottom fw-700 fs-lg-24 fs-md-22 fs-18 text-gray">
                Roadmap
              </div>
              <div className="courseRoadMap d-flex flex-wrap">
                {data?.roadMapData?.map((roadData) => (
                  <div className="courseType position-relative bg-light d-flex align-items-center p-10-y p-15-x border-radius-15 m-15-right m-15-bottom">
                    <div className="courseTypeImg hw-30">
                      <img
                        src={baseURL + roadData.task.image}
                        alt="Image not Found"
                      />
                    </div>
                    <p className="courseTypeText text-customGray fw-600 fs-16 m-10-left">
                      {roadData.task.taskName}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-12 m-15-top">
            <div
              className={`border-radius-10 text-start p-30-xl-x p-20-x p-xl-30-y p-20-y h-100 border-solid-gray2-1 cursor-pointer trans-3 `}
            >
              <div className="p-20-bottom fw-700 fs-lg-24 fs-md-22 fs-18 text-gray">
                Opportunities
              </div>
              <div className="courseOpportunities d-flex flex-wrap">
                {data?.courseOpportunities?.map((opporData, i) => (
                  <div
                    className="accordionBox bg-light border-radius-10 cursor-pointer m-15-right m-15-bottom"
                    key={i}
                  >
                    <div className="accordionHead p-15-y p-20-x d-flex justify-content-between align-items-center">
                      <div className="accordionHeadText fs-16 fw-600 text-customGray">
                        {opporData?.title}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default WebCoursesShow;
