import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  Textarea,
  editData,
  submitData,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import {
  createWebSlider,
  updateWebSlider,
} from "../../../../redux/slice/webSliderSlice";
import Button from "../../../extra/Buttons/Button";

const WebSliderAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();


  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webSliderForm");
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addWebSlider = submitData(e);
    if (addWebSlider) {
      try {
        let response;
        if (dialogueData) {
          const payload = { addWebSlider, webSliderId: dialogueData._id };
          response = await dispatch(updateWebSlider(payload)).unwrap();
        } else {
          response = await dispatch(createWebSlider(addWebSlider)).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webSliderForm`}
      title={`Web Slider Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-6 col-lg-8 col-md-9 col-11`}
    >
      <div className="row align-items-center formBody">
        <div className="col-6">
          <Input
            type={`text`}
            id={`smallTitle`}
            name={`smallTitle`}
            label={`Small Title`}
            errorMessage={`Enter Small Title`}
          />
        </div>
        <div className="col-6">
          <Input
            type={`text`}
            id={`title`}
            name={`title`}
            label={`Title`}
            errorMessage={`Enter Title`}
          />
        </div>
        <div className="col-6">
          <Input
            type={`text`}
            id={`highlight`}
            name={`highlight`}
            label={`Highlight`}
            errorMessage={`Enter Highlight`}
          />
        </div>
        {/* <div className="col-11">
          <Input
            type={`text`}
            name={`highlite`}
            id={`highlite`}
            label={`Type Hightlight`}
            ignore={true}
            value={showText}
            onChange={(e) => setShowText(e.target.value)}
          />
        </div>
        <div className="col-1 h-100">
          <Button
            className={`bg-second text-light border-solid-second-2 w-100 h-100`}
            icon={`ri-arrow-down-circle-line`}
            type={`button`}
            onClick={() => {
              setHighlightText([...hightlightText, showText]);
              setShowText("");
            }}
          />
        </div>
        <div className="col-12">
          <div
            className={`mainHighlightShowBox w-100 bg-light p-10 border-radius-5 d-flex flex-wrap ${
              hightlightText?.length <= 0 && "height-51"
            }`}
          >
            {hightlightText?.map((res, index) => (
              <div className="bg-second text-light m-5 p-10-x p-5-y fs-14 border-radius-5 d-flex align-items-center">
                <div className="m-5-right">{res}</div>
                <i
                  class="ri-close-circle-line fs-16 cursor-pointer"
                  onClick={() =>
                    setHighlightText(
                      hightlightText.filter((_, i) => index !== i)
                    )
                  }
                ></i>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </DialogBox>
  );
};

export default WebSliderAdd;
