import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webSlider: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebSlider = createAsyncThunk(
  "webSlider/getWebSlider",
  async (payload) => {
    return apiInstance.get(`webSlider/getWebSlider`);
  }
);
export const createWebSlider = createAsyncThunk(
  "webSlider/createWebSlider",
  async (payload) => {
    return apiInstance.post("webSlider/createWebSlider", payload);
  }
);
export const updateWebSlider = createAsyncThunk(
  "webSlider/updateWebSlider",
  async (payload) => {
    return apiInstance.patch(
      `webSlider/updateWebSlider?webSliderId=${payload.webSliderId}`,
      payload.addWebSlider
    );
  }
);
export const deleteWebSlider = createAsyncThunk(
  "webSlider/deleteWebSlider",
  async (webSliderId) => {
    return apiInstance.delete(
      `webSlider/deleteWebSlider?webSliderId=${webSliderId}`
    );
  }
);

const webSliderSlice = createSlice({
  name: "webSliderSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebSlider
    builder.addCase(getWebSlider.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebSlider.fulfilled, (state, action) => {
      state.webSlider = action.payload.webSlider;
      state.isSkeleton = false;
    });
    builder.addCase(getWebSlider.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webSliderCreate
    builder.addCase(createWebSlider.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebSlider.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webSlider.unshift(action.payload.webSlider);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebSlider.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebSlider
    builder.addCase(updateWebSlider.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebSlider.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webSliderIdx = state.webSlider.findIndex(
          (webSlider) => webSlider._id === action.payload.webSlider._id
        );
        if (webSliderIdx !== -1) {
          state.webSlider[webSliderIdx] = {
            ...state.webSlider[webSliderIdx],
            ...action.payload.webSlider,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebSlider.rejected, (state, action) => {
      state.isLoading = false;
    });

    // deleteWebSlider
    builder.addCase(deleteWebSlider.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWebSlider.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webSlider = state.webSlider.filter(
          (webSlider) => webSlider._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(deleteWebSlider.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webSliderSlice.reducer;
