import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webCourseOpportunities: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebCourseOpportunities = createAsyncThunk(
  "webCourseOpportunities/getWebCourseOpportunities",
  async (payload) => {
    return apiInstance.get(`webCourseOpportunities/getWebCourseOpportunities`);
  }
);
export const createWebCourseOpportunities = createAsyncThunk(
  "webCourseOpportunities/createWebCourseOpportunities",
  async (payload) => {
    return apiInstance.post("webCourseOpportunities/createWebCourseOpportunities", payload);
  }
);
export const updateWebCourseOpportunities = createAsyncThunk(
  "webCourseOpportunities/updateWebCourseOpportunities",
  async (payload) => {
    return apiInstance.patch(
      `webCourseOpportunities/updateWebCourseOpportunities?webCourseOpportunitiesId=${payload.webCourseOpportunitiesId}`,
      payload.addWebCourseOpportunities
    );
  }
);
export const deleteWebCourseOpportunities = createAsyncThunk(
  "webCourseOpportunities/deleteWebCourseOpportunities",
  async (webCourseOpportunitiesId) => {
    return apiInstance.delete(
      `webCourseOpportunities/deleteWebCourseOpportunities?webCourseOpportunitiesId=${webCourseOpportunitiesId}`
    );
  }
);

const webCourseOpportunitiesSlice = createSlice({
  name: "webCourseOpportunitiesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebCourseOpportunities
    builder.addCase(getWebCourseOpportunities.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebCourseOpportunities.fulfilled, (state, action) => {
      state.webCourseOpportunities = action.payload.webCourseOpportunities;
      state.isSkeleton = false;
    });
    builder.addCase(getWebCourseOpportunities.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webCourseOpportunitiesCreate
    builder.addCase(createWebCourseOpportunities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebCourseOpportunities.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webCourseOpportunities.unshift(action.payload.webCourseOpportunities);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebCourseOpportunities.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebCourseOpportunities
    builder.addCase(updateWebCourseOpportunities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebCourseOpportunities.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webCourseOpportunitiesIdx = state.webCourseOpportunities.findIndex(
          (webCourseOpportunities) => webCourseOpportunities._id === action.payload.webCourseOpportunities._id
        );
        if (webCourseOpportunitiesIdx !== -1) {
          state.webCourseOpportunities[webCourseOpportunitiesIdx] = {
            ...state.webCourseOpportunities[webCourseOpportunitiesIdx],
            ...action.payload.webCourseOpportunities,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebCourseOpportunities.rejected, (state, action) => {
      state.isLoading = false;
    });

    // deleteWebCourseOpportunities
    builder.addCase(deleteWebCourseOpportunities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWebCourseOpportunities.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webCourseOpportunities = state.webCourseOpportunities.filter(
          (webCourseOpportunities) => webCourseOpportunities._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(deleteWebCourseOpportunities.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webCourseOpportunitiesSlice.reducer;
