import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webCourseFacility: [],
  isLoading: false,
  isSkeleton: false,
};
export const webCourseFacilityGet = createAsyncThunk(
  "webCourseFacility/webCourseFacilityGet",
  async (payload) => {
    return apiInstance.get(`webCourseFacility/webCourseFacilityGet`);
  }
);
export const webCourseFacilityAdd = createAsyncThunk(
  "webCourseFacility/webCourseFacilityAdd",
  async (payload) => {
    return apiInstance.post("webCourseFacility/webCourseFacilityAdd", payload);
  }
);
export const webCourseFacilityUpdate = createAsyncThunk(
  "webCourseFacility/webCourseFacilityUpdate",
  async (payload) => {
    return apiInstance.patch(
      `webCourseFacility/webCourseFacilityUpdate?webCourseFacilityId=${payload.webCourseFacilityId}`,
      payload.formData
    );
  }
);
export const webCourseFacilityDelete = createAsyncThunk(
  "webCourseFacility/webCourseFacilityDelete",
  async (webCourseFacilityId) => {
    return apiInstance.delete(`webCourseFacility/webCourseFacilityDelete?webCourseFacilityId=${webCourseFacilityId}`);
  }
);

export const webCourseFacilityAction = createAsyncThunk(
  "webCourseFacility/enableDisableBanner",
  async (payload) => {
    return apiInstance.put(`webCourseFacility/enableDisableBanner?webCourseFacilityId=${payload}`);
  }
);

const webCourseFacilitySlice = createSlice({
  name: "webCourseFacilitySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webCourseFacilityGet
    builder.addCase(webCourseFacilityGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(webCourseFacilityGet.fulfilled, (state, action) => {
      state.webCourseFacility = action.payload.webCourseFacility;
      state.isSkeleton = false;
    });
    builder.addCase(webCourseFacilityGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webCourseFacilityCreate
    builder.addCase(webCourseFacilityAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webCourseFacilityAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webCourseFacility.unshift(action.payload.webCourseFacility);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webCourseFacilityAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webCourseFacilityUpdate
    builder.addCase(webCourseFacilityUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webCourseFacilityUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webCourseFacilityIdx = state.webCourseFacility.findIndex(
          (webCourseFacility) => webCourseFacility._id === action.payload.webCourseFacility._id
        );
        if (webCourseFacilityIdx !== -1) {
          state.webCourseFacility[webCourseFacilityIdx] = {
            ...state.webCourseFacility[webCourseFacilityIdx],
            ...action.payload.webCourseFacility,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webCourseFacilityUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webCourseFacilityDelete
    builder.addCase(webCourseFacilityDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webCourseFacilityDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webCourseFacility = state.webCourseFacility.filter(
          (webCourseFacility) => webCourseFacility._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webCourseFacilityDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webCourseFacilitySlice.reducer;
