import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webCourseCategory: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebCourseCategory = createAsyncThunk(
  "webCourseCategory/getWebCourseCategory",
  async (payload) => {
    return apiInstance.get(`webCourseCategory/getWebCourseCategory`);
  }
);
export const createWebCourseCategory = createAsyncThunk(
  "webCourseCategory/createWebCourseCategory",
  async (payload) => {
    return apiInstance.post("webCourseCategory/createWebCourseCategory", payload);
  }
);
export const updateWebCourseCategory = createAsyncThunk(
  "webCourseCategory/updateWebCourseCategory",
  async (payload) => {
    return apiInstance.patch(
      `webCourseCategory/updateWebCourseCategory?webCourseCategoryId=${payload.webCourseCategoryId}`,
      payload.addWebCourseCategory
    );
  }
);
export const deleteWebCourseCategory = createAsyncThunk(
  "webCourseCategory/deleteWebCourseCategory",
  async (webCourseCategoryId) => {
    return apiInstance.delete(
      `webCourseCategory/deleteWebCourseCategory?webCourseCategoryId=${webCourseCategoryId}`
    );
  }
);

const webCourseCategorySlice = createSlice({
  name: "webCourseCategorySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebCourseCategory
    builder.addCase(getWebCourseCategory.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebCourseCategory.fulfilled, (state, action) => {
      state.webCourseCategory = action.payload.webCourseCategory;
      state.isSkeleton = false;
    });
    builder.addCase(getWebCourseCategory.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webCourseCategoryCreate
    builder.addCase(createWebCourseCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebCourseCategory.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webCourseCategory.unshift(action.payload.webCourseCategory);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebCourseCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebCourseCategory
    builder.addCase(updateWebCourseCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebCourseCategory.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webCourseCategoryIdx = state.webCourseCategory.findIndex(
          (webCourseCategory) => webCourseCategory._id === action.payload.webCourseCategory._id
        );
        if (webCourseCategoryIdx !== -1) {
          state.webCourseCategory[webCourseCategoryIdx] = {
            ...state.webCourseCategory[webCourseCategoryIdx],
            ...action.payload.webCourseCategory,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebCourseCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    // deleteWebCourseCategory
    builder.addCase(deleteWebCourseCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWebCourseCategory.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webCourseCategory = state.webCourseCategory.filter(
          (webCourseCategory) => webCourseCategory._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(deleteWebCourseCategory.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webCourseCategorySlice.reducer;
