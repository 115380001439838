import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../extra/Swal/Alert";
import ToggleSwitch from "../../../extra/Buttons/ToggleSwitch";
import { baseURL } from "../../../util/config";
import {
  deleteWebPortfolio,
  getWebPortfolio,
  updateWebShowCourse,
} from "../../../../redux/slice/webPortfolioSlice";
import WebPortfolioAdd from "./WebPortfolioAdd";

import WebPortfolioShow from "./WebPortfolioShow";
import { getWebPortfolioCategory } from "../../../../redux/slice/webPortfolioCategorySlice";
import { webTechnologyGet } from "../../../../redux/slice/webTechnologySlice";
// import WebTaskAdd from "./WebTaskAdd";
const WebPortfolio = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { webPortfolio } = useSelector((state) => state.webPortfolio);

  useEffect(() => {
    dispatch(getWebPortfolioCategory());
    dispatch(webTechnologyGet());
  }, []);

  // Pagination BOTH

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    dispatch(getWebPortfolio());
  }, []);

  useEffect(() => {
    setData(webPortfolio);
  }, [webPortfolio]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteWebPortfolio(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleShowCourse = (id) => {
    dispatch(updateWebShowCourse(id));
  };

  const webTaskTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
      tdClass: "width-50",
    },

    {
      Header: "Thumbnail",
      body: "thumbnail",
      Cell: ({ row }) => (
        <span>
          <img
            src={baseURL + row.thumbnail}
            className="width-200 height-120"
            alt=""
          />
        </span>
      ),
      tdClass: "width-200",
    },
    {
      Header: "Logo",
      body: "logoImage",
      Cell: ({ row }) => (
        <span>
          <div className="m-auto hw-sm-60 hw-50">
            <img
              src={baseURL + row?.logoImage}
              alt="course"
              style={{ objectFit: "contain" }}
            />
          </div>
        </span>
      ),
      thClass: "justify-content-center",
      tdClass: "width-200",
    },
    {
      Header: "Title",
      body: "title",
      sorting: { type: "client" },
      tdClass: "width-200",
    },
    {
      Header: "Portfolio Category",
      body: "portfolioCategory",
      Cell: ({ row }) => <div>{row.portfolioCategory?.categoryName}</div>,
      thClass: "justify-content-center",
      tdClass: "text-center",
    },
    {
      Header: "Technologies",
      body: "technology",
      Cell: ({ row }) => (
        <div className="d-flex">
          {row.technology.map((res) => (
            <div className="hw-50">
              <img
                src={baseURL + res?.image}
                alt="course"
                style={{ objectFit: "contain" }}
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray border-solid-darkGray-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "webPortfolio",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-danger-light text-danger border-solid-danger-1 m-5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
          <ActionButton
            className="bg-blue-light text-blue border-solid-blue-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "webPortfolioShow",
                  data: row.portfolioLink,
                })
              )
            }
            icon={`ri-information-line`}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"Web Course"} icon={`ri-group-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={webTaskTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m-20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "webPortfolio" }));
              }}
            />
          </div>
        </div>
      </div>

      <Table
        data={data}
        mapData={webTaskTable}
        Page={page}
        serverPerPage={rowsPerPage}
      />

      <Pagination
        type={"server"}
        onPageChange={handleChangePage}
        serverPerPage={rowsPerPage}
        totalData={data?.length}
        serverPage={page}
        setServerPage={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {dialogue && dialogueType === "webPortfolio" && <WebPortfolioAdd />}
      {dialogue && dialogueType === "webPortfolioShow" && <WebPortfolioShow />}
    </>
  );
};

export default WebPortfolio;
