import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { editData, submitData } from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import {
  createWebCourseCategory,
  updateWebCourseCategory,
} from "../../../../redux/slice/webCourseCategorySlice";

const WebCourseCategoryAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webCourseCategoryForm");
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addWebCourseCategory = submitData(e);

    if (addWebCourseCategory) {
      try {
        let response;
        if (dialogueData) {
          const payload = {
            addWebCourseCategory,
            webCourseCategoryId: dialogueData._id,
          };
          response = await dispatch(updateWebCourseCategory(payload)).unwrap();
        } else {
          response = await dispatch(
            createWebCourseCategory(addWebCourseCategory)
          ).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webCourseCategoryForm`}
      title={`Web Course Category Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-6 col-lg-8 col-md-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-12">
          <Input
            type={`text`}
            id={`categoryName`}
            name={`categoryName`}
            label={`Category Name`}
            errorMessage={`Enter Category Name`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default WebCourseCategoryAdd;
