import React, { useEffect, useState } from "react";
import Table from "../../../extra/Tables/Table";
import Title from "../../../extra/Title/Title";
import Pagination from "../../../extra/Pagination/Pagination";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { warning } from "../../../extra/Swal/Alert";
import {
  webTechnologyDelete,
  webTechnologyGet,
} from "../../../../redux/slice/webTechnologySlice";
import ToggleSwitch from "../../../extra/Buttons/ToggleSwitch";
import { baseURL } from "../../../util/config";
import WebTechnologyAdd from "./WebTechnologyAdd";
const WebTechnology = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { webTechnology } = useSelector((state) => state.webTechnology);

  // Pagination BOTH

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    dispatch(webTechnologyGet());
  }, []);

  useEffect(() => {
    setData(webTechnology);
  }, [webTechnology]);

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(webTechnologyDelete(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const webTechnologyTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      ),
      tdClass: "width-50",
    },

    {
      Header: "Image",
      body: "image",
      Cell: ({ row }) => (
        <span>
          <img src={baseURL + row.image} className="hw-30" alt="" />
        </span>
      ),
      tdClass: "width-150",
    },
    {
      Header: "Technology Name",
      body: "technologyName",
      sorting: { type: "client" },
      tdClass: "width-200",
    },
    {
      Header: "Technology Color",
      body: "technologyColor",
      Cell: ({ row }) => (
        <div
          className="hw-20 m-auto border-round-50"
          style={{ backgroundColor: row.technologyColor }}
        ></div>
      ),
      thClass: "justify-content-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-darkGray-light text-darkGray border-solid-darkGray-1 m-5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "webTechnology",
                  data: { ...row, ...row.bankDetails },
                })
              )
            }
            icon={`ri-pencil-fill`}
          />
          <ActionButton
            className="bg-danger-light text-danger border-solid-danger-1 m-5-right"
            onClick={() => handleDelete(row._id)}
            icon={`ri-delete-bin-line`}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="row justify-content-between align-items-center align-items-center adminPageStart m-15-bottom">
        <div className="col-sm-6 col-12">
          <Title name={"WebTechnology"} icon={`ri-group-line`} />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m-10-right">
            <Searching
              type={`server`}
              data={data}
              setData={setData}
              column={webTechnologyTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Button
              className={`bg-gray2 text-darkGray border-solid-darkGray-2 m-20-right`}
              icon={`ri-file-add-line`}
              onClick={() => {
                dispatch(openDialog({ type: "webTechnology" }));
              }}
            />
          </div>
        </div>
      </div>
      <Table
        data={data}
        mapData={webTechnologyTable}
        Page={page}
        serverPerPage={rowsPerPage}
      />
      <Pagination
        type={"server"}
        onPageChange={handleChangePage}
        serverPerPage={rowsPerPage}
        totalData={data?.length}
        serverPage={page}
        setServerPage={setPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {dialogue && dialogueType === "webTechnology" && <WebTechnologyAdd />}
    </>
  );
};

export default WebTechnology;
