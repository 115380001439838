import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webCourse: [],
  webCourseSingle: {},
  isLoading: false,
  isSkeleton: false,
};
export const getWebCourse = createAsyncThunk(
  "webCourse/getWebCourse",
  async (payload) => {
    return apiInstance.get(`webCourse/getWebCourse`);
  }
);
export const getSingleWebCourse = createAsyncThunk(
  "webCourse/getSingleWebCourse",
  async (courseLink) => {
    return apiInstance.get(
      `webCourse/getSingleWebCourse?courseLink=${courseLink}`
    );
  }
);
export const createWebCourse = createAsyncThunk(
  "webCourse/createWebCourse",
  async (payload) => {
    return apiInstance.post("webCourse/createWebCourse", payload);
  }
);
export const updateWebCourse = createAsyncThunk(
  "webCourse/updateWebCourse",
  async (payload) => {
    return apiInstance.patch(
      `webCourse/updateWebCourse?webCourseId=${payload.webCourseId}`,
      payload.convertFormDataCourse
    );
  }
);
export const deleteWebCourse = createAsyncThunk(
  "webCourse/deleteWebCourse",
  async (webCourseId) => {
    return apiInstance.delete(
      `webCourse/deleteWebCourse?webCourseId=${webCourseId}`
    );
  }
);

export const updateWebShowCourse = createAsyncThunk(
  "webCourse/updateWebShowCourse",
  async (id) => {
    return apiInstance.put(`webCourse/updateWebShowCourse?webCourseId=${id}`);
  }
);

const webCourseSlice = createSlice({
  name: "webCourseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebCourse
    builder.addCase(getWebCourse.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebCourse.fulfilled, (state, action) => {
      state.webCourse = action.payload.webCourse;
      state.isSkeleton = false;
    });
    builder.addCase(getWebCourse.rejected, (state, action) => {
      state.isSkeleton = false;
    });
    // getSingleWebCourse
    builder.addCase(getSingleWebCourse.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getSingleWebCourse.fulfilled, (state, action) => {
      state.webCourseSingle = action.payload.webCourse;
      state.isSkeleton = false;
    });
    builder.addCase(getSingleWebCourse.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webCourseCreate;
    builder.addCase(createWebCourse.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebCourse.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webCourse.unshift(action.payload.webCourse);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebCourse.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebCourse
    builder.addCase(updateWebCourse.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebCourse.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webCourseIdx = state.webCourse.findIndex(
          (webCourse) => webCourse._id === action.payload.webCourse._id
        );
        if (webCourseIdx !== -1) {
          state.webCourse[webCourseIdx] = {
            ...state.webCourse[webCourseIdx],
            ...action.payload.webCourse,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebCourse.rejected, (state, action) => {
      state.isLoading = false;
    });

    // deleteWebCourse
    builder.addCase(deleteWebCourse.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWebCourse.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webCourse = state.webCourse.filter(
          (webCourse) => webCourse._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(deleteWebCourse.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebShowCourse
    builder.addCase(updateWebShowCourse.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebShowCourse.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webCourseIdx = state.webCourse.findIndex(
          (webCourse) => webCourse._id === action.payload.webCourse._id
        );
        if (webCourseIdx !== -1) {
          state.webCourse[webCourseIdx] = {
            ...state.webCourse[webCourseIdx],
            ...action.payload.webCourse,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebShowCourse.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webCourseSlice.reducer;
