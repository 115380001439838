import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import { baseURL } from "../../../util/config";
import { useEffect, useState } from "react";
import { getSingleWebPortfolio } from "../../../../redux/slice/webPortfolioSlice";

const WebPortfolioShow = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { webPortfolioSingle } = useSelector((state) => state.webPortfolio);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  useEffect(() => {
    dispatch(getSingleWebPortfolio(dialogueData));
  }, []);
  useEffect(() => {
    setData(webPortfolioSingle);
  }, [webPortfolioSingle]);

  return (
    <DialogBox
      id={`webPortfolioForm`}
      title={`Portfolio Show Dialogue`}
      columns={`col-xxl-8 col-xl-9 col-11`}
      foot={true}
    >
      <div className="portfolioShow p-30">
        <div className="row">
          <div className="col-12">
            <div
              className={`border-radius-10 text-start p-30-xl-x p-20-x p-xl-30-y p-20-y h-100 border-solid-gray2-1 cursor-pointer trans-3`}
            >
              <div className="portfolioMainBanner">
                <img
                  src={baseURL + data?.thumbnail}
                  alt=""
                  className="w-100 border-radius-5"
                />
                <h2 className="fs-25 fw-700 m-10-top">{data?.headTitle}</h2>
              </div>
              <div className="portfolioechnology d-flex flex-wrap justify-content-center m-30-y">
                {data?.technology?.map((tach) => (
                  <div className="portfolioType position-relative bg-light d-flex align-items-center p-10-y p-15-x border-radius-15 m-15-right m-15-bottom">
                    <div className="portfolioTypeImg hw-30">
                      <img src={baseURL + tach.image} alt="Image not Found" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="portfolioDetails">
                <div className="p-20-bottom fw-700 fs-lg-24 fs-md-22 fs-18">
                  <span>{data?.title} </span>
                  <span className="text-second">
                    ({data?.portfolioCategory?.categoryName})
                  </span>
                </div>
                <div
                  className="fs-16 lh-30 text-darkGray"
                  dangerouslySetInnerHTML={{
                    __html: data?.titleHtml,
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="col-12 m-15-top">
            <div
              className={`border-radius-10 text-start p-30-xl-x p-20-x p-xl-30-y p-20-y h-100 border-solid-gray2-1 cursor-pointer trans-3 `}
            >
              <div className="p-20-bottom fw-700 fs-lg-24 fs-md-22 fs-18 text-gray">
                Portfolio Images
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="portfolioImage m-30-bottom height-500 border-radius-5 overflow-hidden border-solid-second-5">
                    <img
                      src={baseURL + data?.homePageImage}
                      alt=""
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="col-8">
                  <div className="portfolioPortfolio">
                    <div className="row">
                      {data?.portfolioImages?.map((images, i) => (
                        <div className="col-4">
                          <div className="m-30-bottom height-300 border-radius-5 overflow-hidden">
                            <img
                              src={baseURL + images}
                              alt=""
                              className="w-100"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default WebPortfolioShow;
