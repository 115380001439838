import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/images/logo.png";
import { baseURL } from "../util/config";
import { getLoginUser } from "../../redux/slice/dashBoardSlice";
import { useEffect } from "react";

const Navbar = () => {
  const { admin, isAuth } = useSelector((state) => state.auth);

  const mainAdmin = admin || JSON.parse(sessionStorage.getItem("token"));
  console.log("image", baseURL + mainAdmin.image);

  const dispatch = useDispatch();

  useEffect(() => {
    if (admin._id) {
      dispatch(getLoginUser(admin._id));
    }
  }, [admin._id]);

  return (
    <>
      <div className="mainNavbar">
        <div className="navBar">
          <div className="innerNavbar betBox">
            <div className="leftNav">
              <i className="ri-bar-chart-horizontal-line cursor-pointer fs-20 navToggle"></i>
            </div>
            <div className="midNav">
              <div className="sideBarLogo">
                <div className="logo width-md-140 width-100">
                  <img src={Logo} alt="logo" />
                </div>
              </div>
            </div>
            {/* <div className="rightNav">
              <div className="adminProfile betBox">
                <div className="adminPic m-8-right">
                  <img src={baseURL + mainAdmin.image} alt="admin" />
                </div>
                <div className="adminDetails">
                  <h6 className="m-0">{mainAdmin.name}</h6>
                  <p className="m-0 text-capitalize">Admin</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
