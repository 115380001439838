import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, { editData, submitData } from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import {
  createWebPortfolioCategory,
  updateWebPortfolioCategory,
} from "../../../../redux/slice/webPortfolioCategorySlice";

const WebPortfolioCategoryAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webPortfolioCategoryForm");
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addWebPortfolioCategory = submitData(e);

    if (addWebPortfolioCategory) {
      try {
        let response;
        if (dialogueData) {
          const payload = {
            addWebPortfolioCategory,
            webPortfolioCategoryId: dialogueData._id,
          };
          response = await dispatch(updateWebPortfolioCategory(payload)).unwrap();
        } else {
          response = await dispatch(
            createWebPortfolioCategory(addWebPortfolioCategory)
          ).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webPortfolioCategoryForm`}
      title={`Web Course Category Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-6 col-lg-8 col-md-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-12">
          <Input
            type={`text`}
            id={`categoryName`}
            name={`categoryName`}
            label={`Category Name`}
            errorMessage={`Enter Category Name`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default WebPortfolioCategoryAdd;
