import React, { useEffect, useState } from "react";
import Title from "../../../extra/Title/Title";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../extra/Buttons/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import {
  attendanceDelete,
  studentAttendanceGet,
  attendanceThisMonthDelete,
  attendanceUpdate,
} from "../../../../redux/slice/studentAttendanceSlice";
import { warning } from "../../../extra/Swal/Alert";
import moment from "moment";
import StudentAttendanceAdd from "./StudentAttendanceAdd";
import StudentAttendanceSingleAdd from "./StudentAttendanceSingleAdd";
import { setToast } from "../../../extra/toast";
const StudentAttendance = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);

  const currDate = new Date();

  const liveMonth = currDate.getMonth() + 1;
  const [choiceMonth, setChoiceMonth] = useState(liveMonth);

  const liveYears = currDate.getFullYear();
  const [choiceYears, setChoiceYears] = useState(liveYears);

  const { attendance, attendanceYear, isRepete } = useSelector(
    (state) => state.studentAttendance
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(studentAttendanceGet(choiceYears));
  }, [choiceMonth, choiceYears]);

  useEffect(() => {
    setData(attendance);
  }, [attendance]);

  const handleYearChanage = async (selectYears) => {
    let responses = await dispatch(studentAttendanceGet(selectYears));
    if (responses?.payload?.status) {
      setChoiceYears(selectYears);
      handleFirstmonth(selectYears, responses.payload.studentAttendance);
    }
  };

  useEffect(() => {
    handleYearChanage(choiceYears);
  }, []);

  const [editedValues, setEditedValues] = useState({});
  const [editedClickValues, setEditedClickValues] = useState("");

  useEffect(() => {
    // Reset editedValues when inRes changes
    setEditedValues({});
  }, []);

  // Fill Attendance
  const handleInputChange = async (event, statusId, clk) => {
    if (statusId) {
      let response;
      if (clk) {
        setEditedValues((prevValues) => ({
          ...prevValues,
          [statusId]: event,
        }));
        response = await dispatch(
          attendanceUpdate({ attendanceId: statusId, status: event })
        );
      } else {
        console.log("event", event);
        // const { value } = event?.target;
        const value = event.nativeEvent.data;
        const valUp =
          value == 0 || value.toLowerCase() == "--"
            ? "-"
            : value == 1 || value.toLowerCase() == "-p"
            ? "P"
            : value == 2 || value.toLowerCase() == "-a"
            ? "A"
            : value == 3 || value.toLowerCase() == "-h"
            ? "H"
            : value == 4 || value.toLowerCase() == "-l"
            ? "L"
            : value;
        const sanitizedValue = valUp?.toUpperCase()?.replace(/[^PHAL-]/g, "");
        if (sanitizedValue) {
          setEditedValues((prevValues) => ({
            ...prevValues,
            [statusId]: sanitizedValue,
          }));
          response = await dispatch(
            attendanceUpdate({ attendanceId: statusId, status: sanitizedValue })
          );
        }
      }
      if (response) {
        if (response?.payload?.status) {
          if (response?.payload?.studentAttendance) {
            await dispatch(
              studentAttendanceGet(response?.payload?.studentAttendance?.year)
            );
            setChoiceMonth(response?.payload?.studentAttendance?.monthId);
            setChoiceYears(response?.payload?.studentAttendance?.year);
          }
        } else {
          setToast(response?.message);
        }
      }
      setEditedClickValues("");
    }
  };

  // Handle Month and Year After ANy Update
  const handleFirstmonth = (years, response) => {
    if (response.length > 0) {
      if (years != liveYears) {
        setChoiceMonth(response[0]?.monthId);
      } else {
        const isInMonth = response.some((item) => item.monthId === liveMonth);
        if (isInMonth) {
          setChoiceMonth(liveMonth);
        } else {
          setChoiceMonth(response[0]?.monthId);
        }
      }
    }
  };

  // Delete Single Student
  const handleStudentDelete = async (id, name, month, year) => {
    const data = warning(`${name}`, `${month}-${year}`);
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          let response = await dispatch(attendanceDelete(id)).unwrap();
          if (response) {
            if (response?.status) {
              if (response?.studentAttendance) {
                const newRes = await dispatch(
                  studentAttendanceGet(response?.studentAttendance?.year)
                );
                if (newRes?.payload?.studentAttendance?.length > 0) {
                  const isInMonth = newRes?.payload?.studentAttendance?.some(
                    (item) =>
                      item._id.toLowerCase() ===
                      response?.studentAttendance?.month?.toLowerCase()
                  );
                  if (isInMonth) {
                    setChoiceMonth(response?.studentAttendance?.monthId);
                    setChoiceYears(response?.studentAttendance?.year);
                  } else {
                    setChoiceMonth(
                      newRes?.payload?.studentAttendance[0]?.monthId
                    );
                    setChoiceYears(newRes?.payload?.studentAttendance[0]?.year);
                  }
                } else {
                  setChoiceMonth(1);
                  setChoiceYears(liveYears);
                }
              }
            } else {
              setToast(response?.message);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // All Month Delete In One Time
  const handleMonthtDelete = async (month, year) => {
    const monthsId = moment()
      .month(month - 1)
      .format("MMMM");
    const data = warning(`${monthsId} - ${year}`, "Ary Your Sure Delete?");
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          let response = await dispatch(
            attendanceThisMonthDelete({ month, year })
          ).unwrap();
          if (response) {
            if (response?.status) {
              if (response?.studentAttendance) {
                const newRes = await dispatch(
                  studentAttendanceGet(response?.studentAttendance?.year)
                );
                setChoiceMonth(newRes?.payload?.studentAttendance[0]?.monthId);
                setChoiceYears(response?.studentAttendance?.year);
              }
            } else {
              setToast(response?.message);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="mainHeader d-flex justify-content-between align-items-center m-10-bottom flex-sm-row flex-column">
        <Title
          name={"Student Attendance"}
          className={`m-0-sm-bottom m-10-bottom`}
          icon={`ri-user-follow-line`}
        />
        <div className="btns">
          <Button
            className="m-sm-10-x m-5-x border-solid-success-2 border-radius-8 bg-success-light p-0 hw-lg-38 hw-30 fs-lg-14 fs-12"
            text={`P`}
            onClick={() => handleInputChange("P", editedClickValues, "D")}
          />
          <Button
            className="m-sm-10-x m-5-x border-solid-danger-2 border-radius-8 bg-danger-light p-0 hw-lg-38 hw-30 fs-lg-14 fs-12"
            text={`A`}
            onClick={() => handleInputChange("A", editedClickValues, "D")}
          />
          <Button
            className="m-sm-10-x m-5-x border-solid-orange-2 border-radius-8 bg-orange-light p-0 hw-lg-38 hw-30 fs-lg-14 fs-12"
            text={`L`}
            onClick={() => handleInputChange("L", editedClickValues, "D")}
          />
          <Button
            className="m-sm-10-x m-5-x border-solid-blue-2 border-radius-8 bg-blue-light p-0 hw-lg-38 hw-30 fs-lg-14 fs-12"
            text={`H`}
            onClick={() => handleInputChange("H", editedClickValues, "D")}
          />
          <Button
            className="m-sm-10-x m-5-x border-solid-darkGray-2 border-radius-8 bg-darkGray-light p-0 hw-lg-38 hw-30 fs-lg-14 fs-12"
            icon={`ri-spam-3-line`}
            onClick={() => handleInputChange("-", editedClickValues, "D")}
          />
          <Button
            className={`m-sm-10-x m-5-x  bg-second text-light border-solid-second-2 height-lg-38 height-30`}
            bIcon={`ri-user-add-line`}
            text={`ADD`}
            type={`button`}
            onClick={() =>
              dispatch(
                openDialog({
                  text: { year: choiceYears, month: choiceMonth },
                  type: "attendanceSingle",
                })
              )
            }
          />
        </div>
      </div>
      <div className="masterAttendanceTable">
        <div className="mainAttendenceTable">
          <div className="thisMonth overflow-x-auto">
            <table>
              <tr>
                {data?.map((selectMonths, i) => {
                  return (
                    <th
                      className={
                        choiceMonth == selectMonths?.monthId && "activeMonth"
                      }
                      onClick={() => setChoiceMonth(selectMonths?.monthId)}
                    >
                      {selectMonths?.month}
                    </th>
                  );
                })}

                <th className="border-0 p-0 bg-transparent position-sticky right-48">
                  <Button
                    className={`bg-third-light text-third border-solid-third-2 m-10-left`}
                    icon={`ri-file-add-line`}
                    type={`button`}
                    onClick={() =>
                      dispatch(
                        openDialog({
                          text: { year: choiceYears },
                          type: "attendance",
                        })
                      )
                    }
                  />
                </th>
                <th
                  className="border-0 p-0 bg-transparent position-sticky"
                  style={{ right: 0 }}
                >
                  <Button
                    className={`bg-second-light text-second border-solid-second-2 m-10-left`}
                    icon={`ri-delete-bin-6-line`}
                    type={`button`}
                    onDoubleClick={() =>
                      handleMonthtDelete(choiceMonth, choiceYears)
                    }
                  />
                </th>
              </tr>
            </table>
          </div>

          {data?.map((selectMonths, i) => {
            return (
              <>
                {choiceMonth == selectMonths?.monthId &&
                  selectMonths?.attendanceData?.map((res, i) => {
                    return (
                      <div className="batchWiseAttendenceTable">
                        <div className="batchTitle">
                          <h4 className="headTitle text-second text-uppercase">
                            Batch {res.batchTime} - {selectMonths.month}-{" "}
                            {selectMonths.year}
                          </h4>
                        </div>
                        <div className="attendanceTable">
                          <table>
                            <tr>
                              <th>No</th>
                              <th>Student Name</th>
                              {res?.batches[0]?.attendance?.map(
                                (statusres, inI) => {
                                  return (
                                    <th>
                                      <div className="fs-12">
                                        {statusres.date}
                                      </div>
                                      <div className="fs-10">
                                        {statusres.day.substring(0, 2)}
                                      </div>
                                    </th>
                                  );
                                }
                              )}
                              <th className="bg-second">
                                <i class="ri-delete-bin-6-line"></i>
                              </th>
                              <th>R</th>
                              <th>L</th>
                              <th>P</th>
                              <th>A</th>
                              <th>H</th>
                              <th>Total</th>
                            </tr>

                            {res?.batches?.map((inRes, inI) => {
                              return (
                                <tr>
                                  <td>{inI + 1}</td>
                                  <td>{inRes.studentName}</td>
                                  {inRes?.attendance?.map((statusres, inI) => {
                                    return (
                                      <td className="attendanceEditer">
                                        <input
                                          type="text"
                                          value={
                                            editedValues[statusres._id] !==
                                            undefined
                                              ? editedValues[statusres._id]
                                              : statusres.status
                                          }
                                          onChange={(event) =>
                                            handleInputChange(
                                              event,
                                              statusres._id
                                            )
                                          }
                                          onFocus={() => {
                                            setEditedClickValues(statusres._id);
                                          }}
                                          maxLength={2}
                                          className={`${
                                            ((statusres.status == "A" ||
                                              editedValues[statusres._id] ==
                                                "A") &&
                                              `${
                                                statusres.isLeave
                                                  ? "bg-danger-light text-danger"
                                                  : "bg-second-light text-second"
                                              }  outline-danger`) ||
                                            ((statusres.status == "H" ||
                                              editedValues[statusres._id] ==
                                                "H") &&
                                              "bg-blue-light text-blue  outline-blue") ||
                                            ((statusres.status == "P" ||
                                              editedValues[statusres._id] ==
                                                "P") &&
                                              "text-success bg-success-light outline-success") ||
                                            ((statusres.status == "L" ||
                                              editedValues[statusres._id] ==
                                                "L") &&
                                              "text-orange bg-orange-light outline-orange")
                                          }`}
                                        />
                                      </td>
                                    );
                                  })}
                                  <td
                                    className="bg-danger-light text-second"
                                    onDoubleClick={() =>
                                      handleStudentDelete(
                                        inRes._id,
                                        inRes.studentName,
                                        inRes.month,
                                        inRes.year
                                      )
                                    }
                                  >
                                    <i class="ri-delete-bin-6-line"></i>
                                  </td>
                                  <td>{inRes?.PCount}</td>
                                  <td>{inRes?.LCount}</td>
                                  <td>
                                    {parseInt(inRes?.PCount) +
                                      parseInt(inRes?.LCount)}
                                  </td>
                                  <td>{inRes?.ACount}</td>
                                  <td className="">{inRes?.HCount}</td>
                                  <td>
                                    {parseInt(inRes?.PCount) +
                                      parseInt(inRes?.LCount) +
                                      parseInt(inRes?.ACount)}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    );
                  })}
              </>
            );
          })}

          <div className="thisYear overflow-x-auto">
            <table>
              <tr>
                {attendanceYear?.map((selectYears, i) => {
                  return (
                    <th
                      className={choiceYears == selectYears && "activeMonth"}
                      onClick={() => {
                        handleYearChanage(selectYears);
                      }}
                    >
                      {selectYears}
                    </th>
                  );
                })}
                <th
                  className="border-0 p-0 bg-transparent position-sticky"
                  style={{ right: 0 }}
                >
                  <Button
                    className={`bg-third-light text-third border-solid-third-2 m-10-left`}
                    bIcon={`ri-file-add-line`}
                    text={`New Data`}
                    type={`button`}
                    onClick={() => {
                      dispatch(
                        openDialog({
                          type: "attendance",
                        })
                      );
                    }}
                  />
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
      {dialogue && dialogueType === "attendance" && (
        <StudentAttendanceAdd
          setChoiceYears={setChoiceYears}
          setChoiceMonth={setChoiceMonth}
        />
      )}
      {dialogue && dialogueType === "attendanceSingle" && (
        <StudentAttendanceSingleAdd
          setChoiceYears={setChoiceYears}
          setChoiceMonth={setChoiceMonth}
        />
      )}
    </>
  );
};

export default StudentAttendance;
