import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  editData,
  Image,
  mixFormateFormData,
  MultiSelect,
  Select,
  submitData,
  Textarea,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";
import Button from "../../../extra/Buttons/Button";
import { setToast } from "../../../extra/toast";
import { warning } from "../../../extra/Swal/Alert";
import { baseURL } from "../../../util/config";
import {
  createWebPortfolio,
  updateWebPortfolio,
} from "../../../../redux/slice/webPortfolioSlice";

const WebPortfolioAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  const { webPortfolioCategory } = useSelector(
    (state) => state.webPortfolioCategory
  );
  const { webTechnology } = useSelector((state) => state.webTechnology);

  const courseCategoryOptions = webPortfolioCategory.map((res) => {
    return { name: res.categoryName, value: res._id };
  });
  const courseOppOptions = webTechnology.map((res) => {
    return {
      name: `<div><img class="hw-20 m-10-right" src="${
        baseURL + res.image
      }"/><span>${res.technologyName}</span></div>`,
      value: res._id,
    };
  });

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webPortfolioForm");
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addWebPortfolio = submitData(e);
    console.log("addWebPortfolio", addWebPortfolio);
    if (addWebPortfolio) {
      const technologyId = addWebPortfolio?.technologyId?.split(",");

      const addSomeCourse = {
        ...addWebPortfolio,
        technologyId: technologyId,
      };

      const convertFormDataCourse = mixFormateFormData(addSomeCourse);
      console.log("addSomeCourse", addSomeCourse);
      console.log("convertFormDataCourse", convertFormDataCourse);
      console.log("...convertFormDataCourse", ...convertFormDataCourse);
      try {
        let response;
        if (dialogueData) {
          const payload = {
            convertFormDataCourse,
            webPortfolioId: dialogueData._id,
          };
          response = await dispatch(updateWebPortfolio(payload)).unwrap();
        } else {
          response = await dispatch(
            createWebPortfolio(convertFormDataCourse)
          ).unwrap();
        }
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webPortfolioForm`}
      title={`Portfolio Dialogue`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-8 col-xl-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Home Page Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-start">
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`title`}
                  name={`title`}
                  label={`Title`}
                  errorMessage={`Enter Title`}
                />
                <Textarea
                  type={`text`}
                  id={`titleHtml`}
                  name={`titleHtml`}
                  label={`Title Html`}
                  errorMessage={`Enter Title Html`}
                  row={4}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Input
                  type={`file`}
                  id={`thumbnail`}
                  name={`thumbnail`}
                  label={`Thumbnail`}
                  imageHandleClass={`width-300 height-150`}
                  errorMessage={`Enter Thumbnail`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Portfolio Page Details
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-start">
              <div className="col-lg-6 col-12">
                <Input
                  type={`text`}
                  id={`portfolioLink`}
                  name={`portfolioLink`}
                  label={`Head Portfolio Link`}
                  errorMessage={`Enter Head Portfolio Link`}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Select
                  id={`portfolioCategoryId`}
                  name={`portfolioCategoryId`}
                  label={`Portfolio Category`}
                  errorMessage={`Enter Portfolio Category`}
                  option={courseCategoryOptions}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Image
                  id={`logoImage`}
                  name={`logoImage`}
                  label={`Logo Image`}
                  errorMessage={`Enter Logo Image`}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Image
                  id={`homePageImage`}
                  name={`homePageImage`}
                  label={`Home Page Image`}
                  errorMessage={`Enter Home Page Image`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Portfolio All Images
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-start">
              <div className="col-12">
                <Image
                  id={`portfolioImages`}
                  name={`portfolioImages`}
                  multiple={true}
                  label={`Portfolio Images`}
                  errorMessage={`Enter Portfolio Images`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 m-20-bottom">
          <div className="text-darkGray fs-14 m-5-bottom fw-600">
            Course Opportinities
          </div>
          <div className="border-dashed-second-1 p-20 border-radius-10">
            <div className="row align-items-center">
              <div className="col-12">
                <MultiSelect
                  type={`text`}
                  id={`technologyId`}
                  name={`technologyId`}
                  label={`Technology`}
                  errorMessage={`Enter Technology`}
                  options={courseOppOptions}
                  keys={`newTechnologyId`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default WebPortfolioAdd;
