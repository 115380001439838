import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webPortfolio: [],
  webPortfolioSingle: {},
  isLoading: false,
  isSkeleton: false,
};
export const getWebPortfolio = createAsyncThunk(
  "webPortfolio/getWebPortfolio",
  async (payload) => {
    return apiInstance.get(`webPortfolio/getWebPortfolio`);
  }
);
export const getSingleWebPortfolio = createAsyncThunk(
  "webPortfolio/getSingleWebPortfolio",
  async (portfolioLink) => {
    return apiInstance.get(
      `webPortfolio/getSingleWebPortfolio?portfolioLink=${portfolioLink}`
    );
  }
);
export const createWebPortfolio = createAsyncThunk(
  "webPortfolio/createWebPortfolio",
  async (payload) => {
    return apiInstance.post("webPortfolio/createWebPortfolio", payload);
  }
);
export const updateWebPortfolio = createAsyncThunk(
  "webPortfolio/updateWebPortfolio",
  async (payload) => {
    return apiInstance.patch(
      `webPortfolio/updateWebPortfolio?webPortfolioId=${payload.webPortfolioId}`,
      payload.convertFormDataCourse
    );
  }
);
export const deleteWebPortfolio = createAsyncThunk(
  "webPortfolio/deleteWebPortfolio",
  async (webPortfolioId) => {
    return apiInstance.delete(
      `webPortfolio/deleteWebPortfolio?webPortfolioId=${webPortfolioId}`
    );
  }
);

export const updateWebShowCourse = createAsyncThunk(
  "webPortfolio/updateWebShowCourse",
  async (id) => {
    return apiInstance.put(
      `webPortfolio/updateWebShowCourse?webPortfolioId=${id}`
    );
  }
);

const webPortfolioSlice = createSlice({
  name: "webPortfolioSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebPortfolio
    builder.addCase(getWebPortfolio.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebPortfolio.fulfilled, (state, action) => {
      state.webPortfolio = action.payload.webPortfolio;
      state.isSkeleton = false;
    });
    builder.addCase(getWebPortfolio.rejected, (state, action) => {
      state.isSkeleton = false;
    });
    // getSingleWebPortfolio
    builder.addCase(getSingleWebPortfolio.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getSingleWebPortfolio.fulfilled, (state, action) => {
      state.webPortfolioSingle = action.payload.webPortfolio;
      state.isSkeleton = false;
    });
    builder.addCase(getSingleWebPortfolio.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webPortfolioCreate;
    builder.addCase(createWebPortfolio.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebPortfolio.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webPortfolio.unshift(action.payload.webPortfolio);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebPortfolio.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebPortfolio
    builder.addCase(updateWebPortfolio.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebPortfolio.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webPortfolioIdx = state.webPortfolio.findIndex(
          (webPortfolio) => webPortfolio._id === action.payload.webPortfolio._id
        );
        if (webPortfolioIdx !== -1) {
          state.webPortfolio[webPortfolioIdx] = {
            ...state.webPortfolio[webPortfolioIdx],
            ...action.payload.webPortfolio,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebPortfolio.rejected, (state, action) => {
      state.isLoading = false;
    });

    // deleteWebPortfolio
    builder.addCase(deleteWebPortfolio.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWebPortfolio.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webPortfolio = state.webPortfolio.filter(
          (webPortfolio) => webPortfolio._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(deleteWebPortfolio.rejected, (state, action) => {
      state.isLoading = false;
    });

    // updateWebShowCourse
    builder.addCase(updateWebShowCourse.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateWebShowCourse.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webPortfolioIdx = state.webPortfolio.findIndex(
          (webPortfolio) => webPortfolio._id === action.payload.webPortfolio._id
        );
        if (webPortfolioIdx !== -1) {
          state.webPortfolio[webPortfolioIdx] = {
            ...state.webPortfolio[webPortfolioIdx],
            ...action.payload.webPortfolio,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateWebShowCourse.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webPortfolioSlice.reducer;
