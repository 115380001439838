import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  Image,
  Textarea,
  editData,
  objectToFormData,
  submitData,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  webServiceAdd,
  webServiceUpdate,
} from "../../../../redux/slice/webServiceSlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";

const WebServiceAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webServiceForm");
    }
  }, [dialogueData]);

  const { webService } = useSelector((state) => state.webService);
  console.log("webService", webService);

  const handleSubmit = async (e) => {
    const addBatch = submitData(e);

    if (addBatch) {
      console.log("addBatch===============", addBatch);
      const formData = objectToFormData(addBatch);

      try {
        let response;
        if (dialogueData) {
          const payload = { formData, webServiceId: dialogueData._id };
          response = await dispatch(webServiceUpdate(payload)).unwrap();
        } else {
          response = await dispatch(webServiceAdd(formData)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webServiceForm`}
      title={`Web Service Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-6 col-lg-8 col-md-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`serviceName`}
            name={`serviceName`}
            label={`Service Name`}
            errorMessage={`Enter Service Name`}
          />
          <Textarea
            row={3}
            id={`serviceDescription`}
            name={`serviceDescription`}
            label={`Service Description`}
            errorMessage={`Enter Service Description`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Image
            type={`file`}
            id={`image`}
            name={`image`}
            label={`Image`}
            errorMessage={`Enter Image`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default WebServiceAdd;
