import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import { useEffect } from "react";

import Dashboard from "../tables/dashboard/Dashboard";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Profile from "../tables/profiles/Profile";
// Staff PROPS
import Staff from "../tables/StaffTables/Staff/Staff";
import PendingLeave from "../tables/StaffTables/StaffLeave/PendingLeave";
import AcceptLeave from "../tables/StaffTables/StaffLeave/AcceptLeave";
import RejectLeave from "../tables/StaffTables/StaffLeave/RejectLeave";
import StaffLeave from "../tables/StaffTables/StaffLeave/StaffLeave";
import StaffAttendance from "../tables/StaffTables/StaffAttendance/StaffAttendance";
import StaffHoliday from "../tables/StaffTables/StaffHoliday/StaffHoliday";
import StaffSalary from "../tables/StaffTables/Salary/StaffSalary";
import AllPendingStaffSalary from "../tables/StaffTables/Salary/AllPendingStaffSalary";
import AllAcceptStaffSalary from "../tables/StaffTables/Salary/AllAcceptStaffSalary";
import SalaryReceipts from "../tables/StaffTables/Salary/SalaryReceipts";
// Student PROPS
import Student from "../tables/StudentsTables/Student/Student";
import StudentAttendance from "../tables/StudentsTables/StudentAttendance/StudentAttendance";
import PendingStudentLeave from "../tables/StudentsTables/StudentLeave/PendingStudentLeave";
import RejectStudentLeave from "../tables/StudentsTables/StudentLeave/RejectStudentLeave";
import AcceptStudentLeave from "../tables/StudentsTables/StudentLeave/AcceptStudentLeave";
import StudentHoliday from "../tables/StudentsTables/StudentHoliday/StudentHoliday";
import StudentLeave from "../tables/StudentsTables/StudentLeave/StudentLeave";
import Receipts from "../tables/StudentsTables/StudentInstallment/Receipt";
import Installment from "../tables/StudentsTables/StudentInstallment/Installment";
import Course from "../tables/StudentsTables/Course/Course";
import Batch from "../tables/StudentsTables/Batches/Batch";
import PendingInquiry from "../tables/StudentsTables/StudentInquiry/PendingInquiry";
import AcceptInquiry from "../tables/StudentsTables/StudentInquiry/AcceptInquiry";
import RejectInquiry from "../tables/StudentsTables/StudentInquiry/RejectInquiry";
import AllReserveStaffSalary from "../tables/StaffTables/Salary/AllReserveStaffSalary";
import WebTask from "../tables/Website/WebTask/WebTask";
import "./navigation.scss";
import WebSlider from "../tables/Website/WebSlider/WebSlider";
import WebCourseCategory from "../tables/Website/WebCourseCategory/WebCourseCategory";
import WebCourseOpportunities from "../tables/Website/WebCourseOpportunities/WebCourseOpportunities";
import WebCourses from "../tables/Website/WebCourses/WebCourses";
import WebCourseFacility from "../tables/Website/WebCourseFacility/WebCourseFacility";
import WebService from "../tables/Website/WebService/WebService";
import WebTechnology from "../tables/Website/WebTechnology/WebTechnology";
import WebPortfolioCategory from "../tables/Website/WebPortfolioCategory/WebPortfolioCategory";
import WebPortfolio from "../tables/Website/WebPortfolio/WebPortfolio";

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (["/", "/admin", "/admin/", ""].includes(location.pathname)) {
  //     navigate("/admin/dashboard");
  //   }
  // }, []);
  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == "/admin/" ||
      location.pathname == ""
    ) {
      navigate("/admin/dashboard");
    }
  }, []);
  return (
    <div className={`mainAdminGrid`}>
      <ResponsiveScript />
      <Sidebar />
      <Navbar />

      <div className={`mainAdmin`}>
        <div className="adminStart">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            {/* Student Router */}
            <Route path="/pendingInquiry" element={<PendingInquiry />} />
            <Route path="/acceptInquiry" element={<AcceptInquiry />} />
            <Route path="/rejectInquiry" element={<RejectInquiry />} />
            <Route path="/student" element={<Student />} />
            <Route path="/studentAttendance" element={<StudentAttendance />} />
            <Route
              path="/pendingStudentLeave"
              element={<PendingStudentLeave />}
            />
            <Route
              path="/acceptStudentLeave"
              element={<AcceptStudentLeave />}
            />
            <Route
              path="/rejectStudentLeave"
              element={<RejectStudentLeave />}
            />
            <Route path="/studentHoliday" element={<StudentHoliday />} />
            <Route path="/receipt" element={<Receipts />} />
            <Route path="/remindingFee" element={<Installment />} />
            <Route path="/studentLeave" element={<StudentLeave />} />
            <Route path="/courses" element={<Course />} />
            <Route path="/batch" element={<Batch />} />

            {/* Staff Router */}
            <Route path="/staffAttendance" element={<StaffAttendance />} />
            <Route path="/staffHoliday" element={<StaffHoliday />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/staffLeave" element={<StaffLeave />} />
            <Route path="/pendingLeave" element={<PendingLeave />} />
            <Route path="/acceptLeave" element={<AcceptLeave />} />
            <Route path="/rejectLeave" element={<RejectLeave />} />
            <Route path="/pendingSalary" element={<AllPendingStaffSalary />} />
            <Route path="/acceptSalary" element={<AllAcceptStaffSalary />} />
            <Route path="/reserveSalary" element={<AllReserveStaffSalary />} />
            <Route path="/salaryRecepts" element={<SalaryReceipts />} />
            <Route path="/salary" element={<StaffSalary />} />

            {/* Web Router */}
            <Route path="/webTask" element={<WebTask />} />
            <Route path="/webSlider" element={<WebSlider />} />
            <Route path="/webCourseCategory" element={<WebCourseCategory />} />
            <Route
              path="/webCourseOpportunities"
              element={<WebCourseOpportunities />}
            />
            <Route path="/webCourses" element={<WebCourses />} />
            <Route path="/webCourseFacility" element={<WebCourseFacility />} />
            <Route path="/webService" element={<WebService />} />
            <Route path="/webTechnology" element={<WebTechnology />} />
            <Route
              path="/webPortfolioCategory"
              element={<WebPortfolioCategory />}
            />
            <Route path="/webPortfolio" element={<WebPortfolio />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Admin;

export const ResponsiveScript = () => {
  useEffect(() => {
    checkWindowSize();

    // Attach the function to the window resize event
    $(window).resize(function () {
      checkWindowSize();
    });

    function checkWindowSize() {
      if ($(window).width() <= 992) {
        $(".mainAdminGrid").addClass("webAdminGrid hoverNav");
      } else {
        $(".mainAdminGrid").removeClass("webAdminGrid hoverNav");
      }
    }
  }, []);

  return null;
};
