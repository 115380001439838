import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input, {
  Image,
  editData,
  objectToFormData,
  submitData,
} from "../../../extra/Inputs/Input";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  webTechnologyAdd,
  webTechnologyUpdate,
} from "../../../../redux/slice/webTechnologySlice";
import DialogBox from "../../../extra/DialogueBox/DialogBox";

const WebTechnologyAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData, "webTechnologyForm");
    }
  }, [dialogueData]);

  const { webTechnology } = useSelector((state) => state.webTechnology);
  console.log("webTechnology", webTechnology);

  const handleSubmit = async (e) => {
    const addWebTechnology = submitData(e);

    if (addWebTechnology) {
      console.log("addWebTechnology===============", addWebTechnology);
      const formData = objectToFormData(addWebTechnology);

      try {
        let response;
        if (dialogueData) {
          const payload = { formData, webTechnologyId: dialogueData._id };
          response = await dispatch(webTechnologyUpdate(payload)).unwrap();
        } else {
          response = await dispatch(webTechnologyAdd(formData)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`webTechnologyForm`}
      title={`Web Technology Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xxl-6 col-lg-8 col-md-9 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`technologyName`}
            name={`technologyName`}
            label={`Technology Name`}
            errorMessage={`Enter Technology Name`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`color`}
            id={`technologyColor`}
            name={`technologyColor`}
            label={`Technology Color`}
            errorMessage={`Enter Technology Color`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Image
            type={`file`}
            id={`image`}
            name={`image`}
            label={`Image`}
            errorMessage={`Enter Image`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default WebTechnologyAdd;
