import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webTechnology: [],
  isLoading: false,
  isSkeleton: false,
};
export const webTechnologyGet = createAsyncThunk(
  "webTechnology/webTechnologyGet",
  async (payload) => {
    return apiInstance.get(`webTechnology/webTechnologyGet`);
  }
);
export const webTechnologyAdd = createAsyncThunk(
  "webTechnology/webTechnologyAdd",
  async (payload) => {
    return apiInstance.post("webTechnology/webTechnologyAdd", payload);
  }
);
export const webTechnologyUpdate = createAsyncThunk(
  "webTechnology/webTechnologyUpdate",
  async (payload) => {
    return apiInstance.patch(
      `webTechnology/webTechnologyUpdate?webTechnologyId=${payload.webTechnologyId}`,
      payload.formData
    );
  }
);
export const webTechnologyDelete = createAsyncThunk(
  "webTechnology/webTechnologyDelete",
  async (webTechnologyId) => {
    return apiInstance.delete(
      `webTechnology/webTechnologyDelete?webTechnologyId=${webTechnologyId}`
    );
  }
);

export const webTechnologyAction = createAsyncThunk(
  "webTechnology/enableDisableBanner",
  async (payload) => {
    return apiInstance.put(
      `webTechnology/enableDisableBanner?webTechnologyId=${payload}`
    );
  }
);

const webTechnologySlice = createSlice({
  name: "webTechnologySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webTechnologyGet
    builder.addCase(webTechnologyGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(webTechnologyGet.fulfilled, (state, action) => {
      state.webTechnology = action.payload.webTechnology;
      state.isSkeleton = false;
    });
    builder.addCase(webTechnologyGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webTechnologyCreate
    builder.addCase(webTechnologyAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webTechnologyAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webTechnology.unshift(action.payload.webTechnology);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webTechnologyAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webTechnologyUpdate
    builder.addCase(webTechnologyUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webTechnologyUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webTechnologyIdx = state.webTechnology.findIndex(
          (webTechnology) =>
            webTechnology._id === action.payload.webTechnology._id
        );
        if (webTechnologyIdx !== -1) {
          state.webTechnology[webTechnologyIdx] = {
            ...state.webTechnology[webTechnologyIdx],
            ...action.payload.webTechnology,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webTechnologyUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webTechnologyDelete
    builder.addCase(webTechnologyDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webTechnologyDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webTechnology = state.webTechnology.filter(
          (webTechnology) => webTechnology._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webTechnologyDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webTechnologySlice.reducer;
