import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  webService: [],
  isLoading: false,
  isSkeleton: false,
};
export const webServiceGet = createAsyncThunk(
  "webService/webServiceGet",
  async (payload) => {
    return apiInstance.get(`webService/webServiceGet`);
  }
);
export const webServiceAdd = createAsyncThunk(
  "webService/webServiceAdd",
  async (payload) => {
    return apiInstance.post("webService/webServiceAdd", payload);
  }
);
export const webServiceUpdate = createAsyncThunk(
  "webService/webServiceUpdate",
  async (payload) => {
    return apiInstance.patch(
      `webService/webServiceUpdate?webServiceId=${payload.webServiceId}`,
      payload.formData
    );
  }
);
export const webServiceDelete = createAsyncThunk(
  "webService/webServiceDelete",
  async (webServiceId) => {
    return apiInstance.delete(
      `webService/webServiceDelete?webServiceId=${webServiceId}`
    );
  }
);

export const webServiceAction = createAsyncThunk(
  "webService/enableDisableBanner",
  async (payload) => {
    return apiInstance.put(
      `webService/enableDisableBanner?webServiceId=${payload}`
    );
  }
);

const webServiceSlice = createSlice({
  name: "webServiceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webServiceGet
    builder.addCase(webServiceGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(webServiceGet.fulfilled, (state, action) => {
      state.webService = action.payload.webService;
      state.isSkeleton = false;
    });
    builder.addCase(webServiceGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webServiceCreate
    builder.addCase(webServiceAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webServiceAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webService.unshift(action.payload.webService);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webServiceAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webServiceUpdate
    builder.addCase(webServiceUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webServiceUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const webServiceIdx = state.webService.findIndex(
          (webService) => webService._id === action.payload.webService._id
        );
        if (webServiceIdx !== -1) {
          state.webService[webServiceIdx] = {
            ...state.webService[webServiceIdx],
            ...action.payload.webService,
          };
        }

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webServiceUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // webServiceDelete
    builder.addCase(webServiceDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webServiceDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webService = state.webService.filter(
          (webService) => webService._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webServiceDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webServiceSlice.reducer;
